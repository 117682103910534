﻿.tick {
    &-success {
        color: #3ace00;
    }

    &-warning {
        color: #d0cb00;
    }

    &-danger {
        color: #b50000;
    }
}


.language-icon-background {
    background-size: auto;
    background-position: left center;
    background-repeat: no-repeat;
    padding: 0px 0px 0px 30px;
    padding: 0px 0px 0px 25px;
}

.fa-xl {
    font-size: 2.2rem;
}

sb-icon.sb-icon-justify {
    width: 2rem;
    text-align: center;
}

.fad-bichromatic {
    .fad {
        &:before {
            color: $brand-primary;
        }
        &:after {
            color: $brand-secondary;
            opacity: 1;
        }
    }
}

.icon-img {
    height: 4rem;
    min-width: 4rem;
}

.icon-img-small {
    height: 2.5rem;
    min-width: 2.5rem;
}