﻿/* Taken from https://stackoverflow.com/questions/2011142/how-to-change-the-style-of-the-title-attribute-inside-an-anchor-tag */

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    position: absolute;
    bottom: -1.6em;
    left: 100%;
    padding: 4px 4px 4px 8px;
    color: #000000;
    white-space: nowrap;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0px 0px 4px #222;
    -webkit-box-shadow: 0px 0px 4px #222;
    box-shadow: 0px 0px 4px #222;
    background-image: -moz-linear-gradient(top, #5cb85c, #cccccc);
    background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #5cb85c),color-stop(1, #5cb86b));
    background-image: -webkit-linear-gradient(top, #5cb85c, #5cb86b);
    background-image: -moz-linear-gradient(top, #5cb85c, #5cb86b);
    background-image: -ms-linear-gradient(top, #5cb85c, #5cb86b);
    background-image: -o-linear-gradient(top, #5cb85c, #5cb86b);
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
}

[data-title] {
    position: relative;
}

// Fake tooltips

.tooltip-fake {
    display: none;
    position: absolute;
    top: calc(100% + 12px);
    background: $gray-darker;
    color: white;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 9999;
}

button:hover .tooltip-fake, a:hover .tooltip-fake {
    display: block;
}

sb-label-list {
    position: relative;
}