@import '../variables/_forms';
@import '../variables/_colours';

ng-multiselect-dropdown {
    color: $text-color;
    .multiselect-dropdown {
        .dropdown-btn {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            font-size: 15px!important;
            background-color: $white;
            padding: 6px 12px!important;
            line-height: 1.4!important;
            font-size: $form-font-size!important;
            border-color: $input-border-color!important;
            .selected-item {
                background: $brand-primary!important;
                border: $brand-primary!important;
                float: none;
                padding: 0.25rem;
                max-width: none!important;
                margin-right: 0!important;
            }
        }
    }
}

.multiselect-single-container {
    ng-multiselect-dropdown {
        .dropdown-btn {
            .selected-item {
                background: transparent!important;
                border: transparent!important;
                color: $gray-base!important;
                max-width: none!important;
                &:hover {
                    box-shadow: none!important;
                }
                a {
                    display: none;
                }
            }
        }
        .filter-textbox {
            padding: 0.5rem!important;
        }
        .multiselect-item-checkbox {
            > div {
                padding-left: 0!important;
                &::before, &::after {
                    display: none!important;
                }
            }
        }
    }
}