@import 'mixins';
@import 'variables/colours.scss';

.card-style {
    @include card-style;
}

.card-style-light {
    @extend .card-style;
    box-shadow: none;
    border-color: $gray-light;
}

.card {
    @extend .card-style;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 2rem;
    min-height: 4.5rem;
}

.card-lowlight {
    opacity: 0.5;
}

.card-table-container {
    @extend.card-style;
    padding: 0;
    margin: 1.75rem;
    .title-bar {
        margin: 0;
        padding: 2rem;
        width: 100%;
        border-color: $gray-lighter;
    }
    .table {
        tr > th {
            border-bottom-color: $gray-lighter;
        }
        tr:first-of-type > td {
            border-top: 0;
        }
        img {
            max-height: 3.5rem;
        }
    }
    sb-loading {
        .row {
            margin: 0;
            .alert {
                margin-top: 15px;
            }
        }
    }
}

.master-detail .card-table-container {
    margin: 0;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    .title-bar {
        padding: 1.25rem 1.5rem;
    }
    .card-table-container__table {
        overflow-y: auto;
        table {
            margin: 0;
            th, td {
                padding: 1.25rem 1.5rem;
            }
        }
    }
}

.card__date {
    min-width: 6rem;
    > div {
        &:first-child {
            color: $brand-primary;
        }
    }
}

.card-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card-list__items {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.5rem 0.65rem;
            border-bottom: solid 1px $gray-lighter;
            &:first-child {
                padding-top: 1rem;
            }
            &:last-child {
                border-bottom: 0;
                padding-bottom: 1rem;
            }
            &[ng-click] {
                @include hover-opacity;
            }
            > div:last-child {
                display: flex;
                align-items: center;
                justify-content: space-between;
                &.row-success, &.row-danger, &.row-warning, &.row-info {
                    padding-left: 1.5rem;
                    > * {
                        padding-left: 0.5rem;
                    }
                }
            }
            > div > div:first-child {
                font-weight: $semi-bold;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
    .card-list__view-all {
        color: $brand-primary;
        font-size: 1.5rem;
        text-align: center;
        padding-top: 1rem;
        a {
            color: $brand-primary;
        }
        @include hover-opacity;
    }
}

// Tabs

$card-tabs-height: 33px;
.card-tabs {
   display: flex;
   height: $card-tabs-height;
   padding-left: $border-radius-large;
   > * {
    border-top-left-radius: $border-radius-large;
    border-top-right-radius: $border-radius-large;
    padding: 0.75rem;
    box-shadow: $box-shadow;
    background: #ffffff;
    cursor: pointer;
        &.active {
            position: relative;
            z-index: 10;
            cursor: default;
            color: $brand-primary;
            box-shadow: 0 5px 0 #fff, 0 0 5px #ccc;;
            border-bottom: 0;
            height: 110%;
            &:after {
                position: absolute;
                content: ' ';
                width: 100%;
                left: 0;
                bottom: -4px;
                height: 4px;
                z-index: 100;
                background: $white;
            }
        }
   }
}