@import './colours';
@import './opacity';

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

//$font-family-sans-serif:  "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-sans-serif: 'Raleway', sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-open-sans: 'Open Sans';

//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base: $font-family-open-sans;

$font-size-base: 13px !default;
$font-size-large: 17px !default;
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1: 20px !default;
$font-size-h2: 17px !default;
$font-size-h3: 14px !default;
$font-size-h4: 12px !default;
$font-size-h5: 11px !default;
$font-size-h6: 9px !default;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: inherit;
$headings-font-weight: 400;
$headings-line-height: 1.1;
$headings-color: inherit;

//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;
//** Text muted color
$text-muted: $gray;
//** Abbreviations and acronyms border color
$abbr-border-color: $gray-light;
//** Headings small color
$headings-small-color: $gray-light;
//** Blockquote small color
$blockquote-small-color: $gray-light;
//** Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25);
//** Blockquote border color
$blockquote-border-color: $gray-lighter;
//** Page header border color
$page-header-border-color: $gray-lighter;
//** Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal;
//** Horizontal line color.
$hr-border: $gray-lighter;


// Font paths

$sb-font-path: '/assets/Fonts/';
$icon-font-path: $sb-font-path + '/bootstrap/';

// Custom

$semi-bold: 600;