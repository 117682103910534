@mixin avatar-size($size) {
    width: $size;
    height: $size;
    min-width: $size;
    min-height: $size;
}

.avatar-initials, .avatar-image {
    border-radius: 100%;
    @include avatar-size(32px);
    font-weight: $semi-bold;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;

    &.avatar-sm {
        @include avatar-size(20px);
        font-size: 9px;
    }

    &.avatar-lg {
        @include avatar-size(48px);
        font-size: 18px;
    }

    &.avatar-xl {
        @include avatar-size(100px);
        font-size: 22px;
    }

    @media (min-width: $screen-sm) {
        @include avatar-size(35px);
    }

}

.avatar-initials {
    background-image: $gray-gradient;
    display: inline-flex;
    color: $white;
    justify-content: center;
    align-items: center;
}

.avatar-loading:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid $brand-secondary;
    border-color: $brand-secondary transparent $brand-secondary transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.avatar-cell {
    width: 58px;
}