.visual-select {
    display: inline-flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
    color: $gray;
    > div {
        width: 100px;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 1.5rem;
        cursor: pointer;
        &.active {
            color: $brand-primary;
            cursor: default;
        }
        &:hover {
            opacity: $hover-opacity;
        }
        i {
            font-size: 25px;
            margin-bottom: 10px;
        }
    }
}