﻿.rhythm-m-0 { margin-bottom: 0;  margin-top: 0; }
.rhythm-m-1 { margin-bottom: $line-height-computed;  margin-top: $line-height-computed; }
.rhythm-m-2 { margin-bottom: $line-height-computed*2; margin-top: $line-height-computed*2; }
.rhythm-m-h { margin-bottom: $line-height-computed*0.5; margin-top: $line-height-computed*0.5; }

.rhythm-m-b-1 { margin-bottom: $line-height-computed; }
.rhythm-m-b-2 { margin-bottom: $line-height-computed*2; }
.rhythm-m-b-h { margin-bottom: $line-height-computed*0.5; }


.rhythm-m-t-1 { margin-top: $line-height-computed; }
.rhythm-m-t-2 { margin-top: $line-height-computed*2; }
.rhythm-m-t-h { margin-top: $line-height-computed*0.5; }

.rhythm-p-1 { padding-bottom: $line-height-computed;  padding-top: $line-height-computed; }
.rhythm-p-2 { padding-bottom: $line-height-computed*2; padding-top: $line-height-computed*2; }
.rhythm-p-h { padding-bottom: $line-height-computed*0.5; padding-top: $line-height-computed*0.5; }

.rhythm-p-b-1 { padding-bottom: $line-height-computed; }
.rhythm-p-b-2 { padding-bottom: $line-height-computed*2; }
.rhythm-p-b-h { padding-bottom: $line-height-computed*0.5; }

.rhythm-p-t-1 { padding-top: $line-height-computed; }
.rhythm-p-t-2 { padding-top: $line-height-computed*2; }
.rhythm-p-t-h { padding-top: $line-height-computed*0.5; }