﻿$switch-height: calc(#{$input-height} * .8) !default;
$switch-height-sm: calc(#{$input-height-sm} * .8) !default;
$switch-height-lg: calc(#{$input-height-lg} * .8) !default;
$switch-border-radius: $switch-height !default;

$switch-checked-bg: $brand-info !default;
$switch-disabled-bg: $custom-control-indicator-disabled-bg !default;
$switch-disabled-color: $custom-control-description-disabled-color !default;
$switch-thumb-bg: $white !default;
$switch-thumb-border-radius: 50% !default;
$switch-thumb-padding: 2px !default;
$switch-focus-box-shadow: 0 0 0 $input-btn-focus-width $brand-info !default;
$switch-transition: .2s all !default;


.switch {
    font-size: $font-size-base;
    position: relative;

    input {
        position: absolute;
        height: 1px;
        width: 1px;
        background: none;
        border: 0;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        overflow: hidden;
        padding: 0;

        + label {
            position: relative;
            min-width: calc(#{$switch-height} * 2);
            border-radius: $switch-border-radius;
            height: $switch-height;
            line-height: $switch-height;
            display: inline-block;
            cursor: pointer;
            outline: none;
            user-select: none;
            vertical-align: middle;
            text-indent: calc(calc(#{$switch-height} * 2) + .5rem);
        }

        + label::before,
        + label::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: calc(#{$switch-height} * 2);
            bottom: 0;
            display: block;
        }

        + label::before {
            right: 0;
            border-radius: $switch-border-radius;
            transition: $switch-transition;
            border-style: solid;
            border-width: 0.15rem;
            border-color: #a6a6a6;
            background-color: #c0c0c0;
        }

        + label::after {
            top: $switch-thumb-padding;
            left: $switch-thumb-padding;
            width: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
            height: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
            border-radius: $switch-thumb-border-radius;
            background-color: $switch-thumb-bg;
            transition: $switch-transition;
        }

        &:checked + label::before {
            background-color: $switch-checked-bg;
        }

        &:checked + label::after {
            margin-left: $switch-height;
        }

        &:focus + label::before {
            outline: none;
            box-shadow: $switch-focus-box-shadow;
        }

        &:disabled + label {
            cursor: not-allowed;
        }

        &:disabled:not(:checked) + label::before {
            background-color: $switch-disabled-bg;
        }
        
        &:disabled:checked + label:before {
            filter: grayscale(0.6) brightness(1.3);
        };
    }
    // Small variation
    &.switch-sm {
        font-size: $font-size-sm;

        input {
            + label {
                min-width: calc(#{$switch-height-sm} * 2);
                height: $switch-height-sm;
                line-height: $switch-height-sm;
                text-indent: calc(calc(#{$switch-height-sm} * 2) + .5rem);
            }

            + label::before {
                width: calc(#{$switch-height-sm} * 2);
            }

            + label::after {
                width: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
                height: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
            }

            &:checked + label::after {
                margin-left: $switch-height-sm;
            }
        }
    }
    // Large variation
    &.switch-lg {
        font-size: $font-size-lg;

        input {
            + label {
                min-width: calc(#{$switch-height-lg} * 2);
                height: $switch-height-lg;
                line-height: $switch-height-lg;
                text-indent: calc(calc(#{$switch-height-lg} * 2) + .5rem);
            }

            + label::before {
                width: calc(#{$switch-height-lg} * 2);
            }

            + label::after {
                width: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
                height: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
            }

            &:checked + label::after {
                margin-left: $switch-height-lg;
            }
        }
    }

    + .switch {
        margin-left: 1rem;
    }

    @each $name, $colour in $ssoProviders {
        &.switch-#{$name} {
            input {
                &:checked + label::before {
                    background-color: $colour;
                }
            }
        }
      }
}