@import './variables/borders';

// Interaction

@mixin hover-opacity {
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
}

// Layout

@mixin align-center-justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

@mixin rounded {
    border-radius: 50%;
}

@mixin card-style {
    box-shadow: $box-shadow;
    padding: 1.5rem;
    background: $white!important;
    border: solid 1px $gray-lighter;
    border-radius: $border-radius-large;
}

@mixin title-bar-top {
    color: white!important;
    background-color: $brand-primary;
    margin: 0;
    width: 100%;
    h3 {
        font-size: 22px;
        font-weight: 500!important;
    }
    .title-bar__description, .title-bar__body__description {
        color: $off-white;
    }
    .title-bar__body__note {
        color: $brand-secondary;
    }
    .btn-link {
        font-size: 1.5rem!important;
        &:not(.dropdown-item) {
            color: white!important;
        }
    }
}