
$date-range-picker-button-width: 36px;

sb-date-range-picker {
    position: relative;
    display: flex;
    input.form-control {
        padding-right: calc(#{$date-range-picker-button-width} + 12px);
    }
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25em 0.75em;
        border: solid 1px $gray-light;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background: $off-white;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        z-index: 2;
        pointer-events: none;
        width: $date-range-picker-button-width;
    }
    input.form-control, div {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.daterangepicker {
    min-width: 290px;
    .cancelBtn {
        display: none;
    }
    td::after {
        display: none;
    }
    tr:nth-child(2) > th {
        font-size: 1.1rem;
        padding: 0!important;
    }
}

@media (max-width: $screen-xs-max) {
    .daterangepicker {
        .ranges
        > ul {
            column-count: 2;
            margin-bottom: 1rem;
        }
        .applyBtn {
            width: 100%;
            margin-bottom: 1rem;
        }
    }
}

// Forced larger width as date range picker needs more than out standardised flex item width

@media (min-width: $screen-xs-max) {
    .form-group, .form-item {
        sb-date-range-picker {
            width: 35rem;
        }
    }
}