.table {

  thead,
  tbody,
  tfoot {

    tr.danger > *,
    td.danger,
    th.danger {
      background-color: #f2dede;
    }

    tr.warning > *,
    td.warning,
    th.warning {
      background-color: #fcf8e3;
    }

    tr.info > *,
    td.info,
    th.info {
      background-color: #d9edf7;
    }

    tr.success > *,
    td.success,
    th.success {
      background-color: #dff0d8;
    }

    tr.active > *,
    td.active,
    th.active {
      background-color: #eeeeee;
    }
  }
}
