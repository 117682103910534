﻿.table > thead > tr > th {
    border-bottom-width: 1px;
}

.table > tbody > tr > td.align-middle {
    vertical-align: middle;
}

.table > tbody > tr.no-border > td, th {
    border: 0px;
}

.table {
    display:table;

    thead {
        &.static {
            background-color: #fff;
            box-shadow: 0 1.5px 2.5px rgba(0, 0, 0, 0.07);
        }
    }

    tbody tr.odd {
        background: #f9f9f9;
    }

    tbody tr.disabled {
        background-color: #fff;
        color: $gray-light;
    }

    > tbody > tr.color0 {
        background-color: $table-bg-accent;
    }

    > tbody > tr.color1 {
        background-color: $white;
    }

    .stacked-button-cluster {
        button {
            display: block;
            margin-bottom: 0.4em;
            width:100%;
        }
    }

    .table-cell {
        display:table-cell;
    }

    &.dont-wrap-headers {
        th {
            white-space: nowrap;
        }
    }
}

.table-valign-middle {
    > tbody > tr > td {
        vertical-align: middle;
    }
}

.table-hover {
    > tbody > tr {
        cursor: pointer;
        &:hover {
            background-color: $gray-lighter!important;
        }
    }
}

.table-hover-cells > tbody > tr > td.hover:hover {
  background-color: #d5d5d5;
  cursor: pointer;
}

.table-hover-cells > tbody > tr > td.active:hover,
.table-hover-cells > tbody > tr.active > td:hover {
  background-color: #e8e8e8;
  cursor: pointer;
}

.table-hover.table-hover-cells > tbody > tr:hover > td:hover {
  background-color: #e8e8e8;
  cursor: pointer;
}

.table-hover.table-hover-cells > tbody > tr.active:hover > td:hover {
  background-color: #d8d8d8;
  cursor: pointer;
}

.table-striped {
    background-color: $table-striped-bg;
    box-shadow: $box-shadow;
    .related {
        background: #fafad2;
    }

    tbody > tr.related:nth-of-type(odd) {
        background: #fafad2;
    }
}

.table-striped, .table-striped-buttons {
    max-width: 98%;
    margin-left: auto;
    margin-right: auto;
}

.table-restrict {
    th, td {
        word-wrap: break-word;
        max-width: 250px;
    }
}

.card-style, .card, .modal {
    .table-striped {
      background: $table-bg;
      box-shadow: none;
      max-width: none;
    }
  }

.table .alert-warning {
    background-color: #fcf8e3 !important;
    border-color: #faebcc !important;
    color: #8a6d3b !important;
}

@media print {
    .table {
        thead, tbody {
            font-size:0.7em;
        }
    }
}

.table-justified {
    table-layout:fixed;
}

[fixed-first-column] {
    position: relative
}

[fixed-first-column] > .table-responsive {
    margin-left: 120px;
}

[fixed-first-column]>.table-responsive>div>.table:first-child {

    > thead > tr > td:first-child,
    > tbody > tr > td:first-child,
    > thead > tr > th:first-child,
    > tbody > tr > th:first-child {
        position: absolute;
        min-width: 120px;
        width: 120px;
        border-right: 1px solid #ddd !important;
        left: 0;
        top: auto;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    > thead > tr > td:first-child > div,
    > tbody > tr > td:first-child > div,
    > thead > tr > th:first-child > div,
    > tbody > tr > th:first-child > div {
        padding-top: 5px;
        padding-bottom: 5px;
        position: relative;
    }

    [fixed-first-column] td {
        min-width: 50px; /* default width */
        max-width: 300px;
    }
}

.no-left-padding {
  > tbody {
    > tr {
      > td {
        &:first-child {
          padding-left: 0px;
        }
      }
    }
  }
}

.acc-level-5 {
    font-size: 0.8em;
}


@media screen and (max-width: $screen-xs-max) {
    /* because this uses the xs breakpoints, you can use the bootstrap xs responsive helper utils like hidden-xs to hide stuff as required */
    table.table-mobile {
        border: 0;

        > caption {
            font-size: 1.3em;
        }

        > thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        > tbody {

            > tr {
                border: 1px solid #ddd;
                border-bottom: 3px solid #000;
                display: block;
                margin: $grid-gutter-width*0.25;
                margin-left: 0em;
                margin-right: 0em;

                &.group-heading {
                    border-top: 1px solid #000;
                    background-color: #ddd;
                }


                > td {
                    border-bottom: 1px solid #ddd;
                    display: block;
                    font-size: .8em;
                    text-align: right;

                    &:before {
                        content: attr(data-label);
                        float: left;
                        font-weight: bold;
                        text-transform: uppercase;
                    }

                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }
    }

    table.table-grouped {
        > tbody {
            padding-top: $grid-gutter-width*0.25;
            padding-bottom: $grid-gutter-width*0.5;
            display: block;

            > tr {
                border-top: none;
                border-bottom-width: 1px;
                display: block;
            }
        }
    }
}

.table-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-shrink: 0;
        > div {
            flex-grow: 1;
            padding: 5px;
        }
        .multiselect-parent {
            padding: 0;
            width: 100%;
            > button {
                width: 100%;
            }
         }
        }
}
.table-filter-wrapper {
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 10px;
    position: relative;
    max-height: 90vh;
    @extend .mt-3, .pt-4;
}

// Row styles

.row-primary{
    border-left: solid 3px $brand-primary;
}

.row-secondary{
    border-left: solid 3px $brand-secondary;
}

.row-success{
    border-left: solid 3px $brand-success;
}

.row-danger{
    border-left: solid 3px $brand-danger;
}

.row-warning{
    border-left: solid 3px $brand-warning;
}

.row-info{
    border-left: solid 3px $brand-info;
}

.row-neutral{
    border-left: solid 3px $gray-light;
}

.row-neutral-dark{
    border-left: solid 3px $gray-darker;
}

.table-scrollable {
    max-height: 50vh;
    overflow-y: auto;
    padding-right: 1.5rem;
    table {
        margin-bottom: 0;
    }
}

.row-primary{
    border-left: solid 0.15em $brand-primary;
}

.row-secondary{
    border-left: solid 0.15em $brand-secondary;
}

.table.table-transparent {
    background-color: transparent;
    border-color: transparent;
}

table.table-mobile.table-transparent > tbody > tr {
    border: 0;
}

.table.table-align-cells-middle > tbody > tr > td {
    vertical-align: middle;
}

.table.table-transparent {
    background-color: transparent;
    border-color: transparent;
}

table.table-mobile.table-transparent > tbody > tr {
    border: 0;
}

.table-responsive {
    border: 0;
    padding: 0;
    /*  Overrides white-space: nowrap; so that long unbroken text
        e.g. email addresses wraps at smaller sizes instead of overlapping the next column.
    */
    @media screen and (max-width: $screen-xs-max) {
              > .table {
                > thead,
                > tbody,
                > tfoot {
                  > tr {
                    > th,
                    > td {
                      white-space: normal;
                    }
                  }
                }
              }
            }
}

.th-day {
    padding-right: 1.25rem!important;
    padding-top: 1.5rem!important;
    width: 0.1rem;
    > * {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        > * {
            font-weight: normal;
            font-size: 1.2rem;
            transform: rotate(-65deg);
            font-weight: $semi-bold;
            width: 1.5em;
        }
    }
    &:last-of-type {
        border-top-right-radius: 5px;
    }
}

.th-day, .td-day {
    background-color: $white;
    text-align: center;
    &:nth-of-type(2n) {
        background-color: $table-bg-accent;
    }
}

tr:last-child {
    .td-day {
        &:last-of-type {
            border-bottom-right-radius: 5px;
        }
    }
}

.td-icon {
    max-width: 50px;
}

.tr-clickable {
    cursor: pointer;
    &:hover {
        opacity: $hover-opacity;
    }
}

// Selectable rows

.tr-selected {
    @extend .sb-bg-secondary-light;
}

// Time picker inside table cells

table sb-time-picker select {
    width: 6rem!important;
}
