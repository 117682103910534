/* Register table */

.register .table {
    border-bottom: solid 1px $gray-lighter;
    th {
        border-bottom: 0;
    }
}

/* Rows */

.table > tbody > tr.tr-register-main, .table > tbody > tr.tr-register-section-heading {
    > td {
        vertical-align: middle;
        border-top-color: $gray-lighter;
    }
}

.table > tbody > tr.tr-register-main
 > td {
        .fa-info-circle {
            color: $gray;
        }
        .register-state-label {
            width: 60px;
            display: inline-block;
        }
        span {
            white-space: normal;
        }
}

.table.table-register-transport > tbody {
   > tr.tr-register-main, > tr.tr-register-notes {
        > td {
            &:first-child {
                padding-left: 1.75rem;
                @media (max-width: $screen-xs-max) {
                    padding-left: 1rem;
                }
                .avatar-cell {
                    width: 65px;
                }
            }
        }
    }
}

.tr-register-actions, .tr-register-notes {
    border-top-style: hidden;
}

.tr-register-actions {
    td {
        div {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            > div {
                padding: 0.2em;
                @media (min-width: $screen-sm-min) {
                    flex-basis: 200px;
                }
                button {
                   width: 100%;
                   min-width: 0;
                }
            }
            > div.exited {
                color: $gray-dark;
                justify-content: center;
                div {
                    flex: 0;
        
                }
                input {
                    margin: 0px 0px 0px 5px;
                }
            }

        }
    }
    .btn {
    text-align: center;
    padding: 12px 28px 10px;
    @media (max-width: $screen-xs-max) {
        padding: 1rem;
        min-width: 4.5rem;
        margin-left: 1rem;
    }
    border-radius: 3px;
    font-weight: $semi-bold;
    color: #ffffff;
    border: none;
    display: inline-block;
    margin: 0;
    }
}

.tr-register-notes > td > div {
        color: $gray-dark;
        padding: 0.5em;
        border-left: solid 0.1em $gray-lighter;
        margin-bottom: 0.2em;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        line-height: 2.5rem;
        white-space: normal;
        &:last-of-type{
            margin-bottom: 0;
        }
        div:first-of-type {
            padding-right: 0.5em;
        }
        .badge {
            margin-left:0.5rem;
        }
        .fa-edit {
            cursor: pointer;
        }
        }

.register-top {
    display: flex;
    flex-direction: column-reverse;
}

@media(min-width: $screen-sm-min) {
    .register-top {
        flex-direction: column;
    }
}

.register-top__actions {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    > div {
        display: flex;
        align-items: center;
        > * {
            margin-right: 1rem;
        }
    }
}

@media(min-width: $screen-sm-min) {
    .register-top__actions {
        flex-direction: row;
    }
}

.register-top__summary {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
}

.register-stats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > div {
        padding: 10px;
        &:last-child {
            padding-right: 0;
        }
    }
}

@media(min-width: 1400px) {
    .register-top__summary {
        flex-direction: row;
    }
}

@media(max-width: $screen-xs-max) {

    .register-stats {
        background: $gray-lighter;
        padding: 5px;
        border-radius: 5px;
        margin-bottom: 15px;
    }
}

// Listings

.listing--users li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::before {
        content: none;
    }
    &::after {
        content: none;
    }
    i {
        font-size: 2rem;
    }
}

// Download

.save-file-types {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    > * {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem;
        font-size: 1.4rem;
        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        i {
            font-size: 4rem;
            margin-bottom: 1.5rem;
        }
        button {
            i {
                font-size: 1.5rem;
                margin: 0;
            }
        }
        h2 {
            font-size: 2rem;
            margin-bottom: 1.5rem;
        }
        .btn {
            margin-top:1.5rem;
        }
        li {
            margin-bottom: 1rem;
        }
    }
}