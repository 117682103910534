.visible-xl {
    display: none;
    @media(min-width: $screen-xl) {
        display: revert;
    }
}
.hidden-xl {
    display: revert;
    @media(min-width: $screen-xl) {
        display: none;
    }
}

