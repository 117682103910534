﻿.category-icon {
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: middle;
}

    .category-icon__icon {
        &:before {
            font-size: 30px;
        }
    }

    .category-icon__badge {
        position: absolute;
        top: 0;
        right: 0;
        margin: 5px -10px 0 0;
    }