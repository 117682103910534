.call {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .call-participants {
        overflow: auto;
        width: 100%;
    }
    .call-participants, .call-self {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        > div {
            position: relative;
            flex-grow: 1;
            flex-basis: 300px;
            max-width: 600px;
            text-align: center;
            .call-participant__video-disabled {
                background: $gray-darker;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .fa-video-slash {
                font-size: 4rem;
                color: $white;
            }
            .fa-microphone-slash {
                @extend .ml-1;
                @extend .text-danger;
            }
            > video {
                width: 100%;
                max-height: 60vh;
            }
            > div {
                position: absolute;
                bottom: 5px;
                right: 0;
                padding: 0.5rem;
                color: $white;
                background: $gray-translucent;
                z-index: 1;
            }
        }
    }
    .call-footer {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
        max-height: 100px;
        z-index: 1;
        > * {
            flex: 1;
            &.call-self {
                display: flex;
                justify-content: flex-start;
                position: relative;
                > #twilioLocalParticipantContainer {
                    position: absolute;
                    bottom: 0;
                    left: 1rem;
                    max-height: 180px;
                    max-width: 250px;
                    display: flex;
                    margin: 0;
                }
            }
            &.call-actions {
                display: flex;
                align-items: center;
                justify-content: center;
                > * {
                    padding: 1rem 2.25rem;
                    .btn {
                        min-width: auto;
                        padding: 1rem;
                        border-radius: 50%;
                        font-size: 1.75rem;
                        height: 5rem;
                        width: 5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 1px 1px rgba(50, 50, 50, 0.1);
                        > * {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        i {
                            margin: 0;
                        }
                        &.btn-danger {
                            i {
                                transform: rotate(135deg);
                            }
                        }
                        &.btn-default {
                            color: $brand-info;
                            border: solid 1px;
                        }
                    }
                }
            }
            &.call-clock {
                font-size: 1.75rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 2rem;
                padding-top: 3rem;
            }
        }
    }
}