//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// Extra large screen / very wide desktop
$screen-xl: 1400px;
$screen-xl-min: $screen-xl;
$screen-xl-desktop: $screen-lg-min;

// SB break point for navbar and others

$sb-custom-navbar: 1270px;
$sb-custom-navbar-min: $sb-custom-navbar;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 0.02px);
$screen-sm-max: ($screen-md-min - 0.02px);
$screen-md-max: ($screen-lg-min - 0.02px);
$screen-md-max: ($screen-xl-min - 0.02px);
$sb-custom-navbar-hidden: ($sb-custom-navbar-min - 0.02px);

// Custom

$screen-xxl: 1800px;
$screen-xl-max: $screen-xxl - 1;
