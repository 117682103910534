﻿.tab-pane {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

/* Mode tabs */

.mode-tabs, .nav-tabs {
    display: flex;
    border-bottom: 0;
    margin-bottom: 0;
    overflow-x: auto;
    /* width */
    &::-webkit-scrollbar {
        height: 10px;
        @media(hover: none) {
            display: none;
        }
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        margin-top: 10px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    > div, li {
        color: $gray;
        text-align: center;
        font-size: 1.4rem;
        border-bottom: 3px solid transparent;
        margin-right: 0.5rem;
        margin-bottom: 0;
        cursor: pointer;
        &:hover {
            border-bottom: 3px solid $brand-primary;
        }
        &.active {
            > a, a:hover, a:focus, a:active {
                color:inherit;
                border: 0;
                background: none;
            }
        }
        &.active {
            border-bottom: 3px solid $brand-primary;
            cursor: default;
            color: $brand-primary;
            .badge {
                background-color: $brand-primary;
            }
        }
        &.mode-tabs__action {
            flex-grow: 1;
            text-align: right;
            &:hover {
                border-color: transparent;
            }
            button {
                font-size: 1.9rem;
                padding: 0;
                line-height: 0;
            }
            > .dropdown {
                display: inline;
                .dropdown-menu > li > a {
                    font-size: 1.75rem;
                }
            }
        }
        &:last-child {
            margin-right: 0;
        }
        .badge {
            margin-left: 0.75rem;
            background-color: $gray;
            color: $white;
        }
        > a, a:hover, a:focus, a:active {
            color:inherit;
            border: 0;
            background: none;
        }
    }
    > div {
        padding: 14px 16px;
    }
}

.mode-tabs {
    margin-bottom: 2.5rem;
}

.nav-tabs {
    margin-bottom: 1rem;
    padding-bottom: 1.5rem;
}

.nav-tabs-responsive {
    max-width: 100%;
    overflow: auto;
    > li {
        white-space: nowrap;
    }
}

.tab-pane {
    border: 0;
}