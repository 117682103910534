@import sortable

+sortable

table[data-sortable].sortable-theme-bootstrap
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif
    font-size: 14px
    line-height: 20px
    color: #333
    background: #fff

    thead th
        border-bottom: 2px solid #e0e0e0

    tbody td
        border-top: 1px solid #e0e0e0

    th[data-sorted="true"]
        color: #3a87ad
        background: #d9edf7
        border-bottom-color: #bce8f1

        &[data-sorted-direction="descending"]:after
            border-top-color: #3a87ad

        &[data-sorted-direction="ascending"]:after
            border-bottom-color: #3a87ad

    &.sortable-theme-bootstrap-striped

        tbody > tr:nth-child(odd) > td
            background-color: #f9f9f9