// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

// [converter] This is defined recursively in LESS, but Sass supports real loops
@use "sass:math";

@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ceil(($grid-gutter-width * 0.5));
    padding-right: floor(($grid-gutter-width * 0.5));
  }
}


// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin float-grid-columns($class, $i: 1, $list: ".col-#{$class}-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-#{$class}-#{$i}";
  }
  #{$list} {
    float: left;
  }
}




@mixin calc-rtl-grid-column($index, $class, $type) {
    @if ($type == width) and ($index > 0) {
        .col-#{$class}-rtl-#{$index} {
            width: percentage(math.div($index, $grid-columns));
        }
    }

    @if ($type == push) and ($index > 0) {
        .col-#{$class}-rtl-push-#{$index} {
            left: percentage(math.div($index, $grid-columns));
        }
    }

    @if ($type == push) and ($index == 0) {
        .col-#{$class}-rtl-push-0 {
            left: auto;
        }
    }

    @if ($type == pull) and ($index > 0) {
        .col-#{$class}-rtl-pull-#{$index} {
            right: percentage(math.div($index, $grid-columns));
        }
    }

    @if ($type == pull) and ($index == 0) {
        .col-#{$class}-rtl-pull-0 {
            right: auto;
        }
    }

    @if ($type == offset) {
        .col-#{$class}-rtl-offset-right-#{$index} {
            margin-right: percentage(math.div($index, $grid-columns));
            margin-left:0 !important
        }
    }
}


// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loop-rtl-grid-columns($columns, $class, $type) {
    @for $i from 0 through $columns {
        @include calc-rtl-grid-column($i, $class, $type);
    }
}


// Create grid for specific class
@mixin make-rtl-grid($class) {
    @include loop-rtl-grid-columns($grid-columns, $class, width);
    @include loop-rtl-grid-columns($grid-columns, $class, pull);
    @include loop-rtl-grid-columns($grid-columns, $class, push);
    @include loop-rtl-grid-columns($grid-columns, $class, offset);

}
