﻿$badge-bg-lighten: 15%;
$badge-text-darken: 40%;

.badge {
    font-weight: normal;
    font-style: normal;
    background: $brand-danger;
    border-radius: 2px;
    padding: 0.5rem 0.75rem;
    text-transform: uppercase;
    &.badge-autocase {
        text-transform: none;
    }
}

.nav, .navbar {
    .badge {
        border-radius: 10px;
        font-weight: $semi-bold;
    }
}

.navbar .badge {
    border-radius: 10px;
    font-weight: $semi-bold;
}

.badge[href]:hover, .badge[href]:focus, .badge[ng-click]:hover, .badge[ng-click]:focus {
    opacity: $hover-opacity;
}

.badge-success {
    background-color: lighten($brand-success, $badge-bg-lighten);
    color: darken($brand-success, $badge-text-darken);
    font-weight: $semi-bold;
}

.badge-info {
    background-color: lighten($brand-info, $badge-bg-lighten);
    color: darken($brand-info, $badge-text-darken);
    font-weight: $semi-bold;
}

.badge-warning {
    background-color: lighten($brand-warning, $badge-bg-lighten);
    color: darken($brand-warning, $badge-text-darken);
    font-weight: $semi-bold;
}

.badge-danger {
    background-color: lighten($brand-danger, $badge-bg-lighten);
    color: darken($brand-danger, $badge-text-darken);
    font-weight: $semi-bold;
}

.badge-primary {
    background-color: $brand-primary;
    color: #fff;
}

.badge-secondary {
    background-color: $brand-secondary;
    color: #fff;
}

.badge-muted {
    background-color: $text-muted;
    color: $gray-dark;
}

.badge-neutral {
    background-color: $gray-light;
    color: #fff;
}

.badge.badge-square {
    border-radius: $border-radius-base;
}