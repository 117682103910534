﻿.label {
    font-weight: normal;
    border-radius: 0;
}
.form-horizontal .control-label.text-left {
    text-align:left;
}

.sb-bg-light-15,
.alert-success {
    label {
        font-weight: normal;
    }
}




