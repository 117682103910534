﻿@use "sass:math";

.dropdown-menu {
    background-color: $white;
}

.relative-dropdown {
    position: relative;
    .dropdown-menu {
        min-width:100%;
    }
}
.locale-picker {
    .dropdown-toggle {
        padding-left: floor(($grid-gutter-width * 0.5)) ;
        padding-right: floor(($grid-gutter-width * 0.5)) ;
        display: flex;
        align-items: center;
        sb-icon {
            display: flex;
        }
    }
    .dropdown-menu {
        > li {
            > a {
                padding-left: floor(math.div($grid-gutter-width, 3));
                padding-right: floor(math.div($grid-gutter-width, 3));
            }
        }
    }
}

.logged-out {
    .locale-picker {
        .dropdown-toggle {
            @extend .btn, .btn-default;
            display: flex;
        }
    }
}
 
@media(max-width: $screen-xs) {
    .dropdown-menu-lg {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $gray-translucent;
        padding: 10vw;
        pointer-events: none;
        margin: 0;
        border-radius: 0;
        -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 0.5s; /* Firefox < 16 */
        -ms-animation: fadein 0.5s; /* Internet Explorer */
        -o-animation: fadein 0.5s; /* Opera < 12.1 */
            animation: fadein 0.5s;
        li {
            width: 100%;
            background-color: $white;
            pointer-events: all;
            padding: 0.25rem 0;
            &:first-child {
                border-top-left-radius: $border-radius-large;
                border-top-right-radius: $border-radius-large;
                padding-top: 0.5rem;
            }
            &:last-child {
                border-bottom-left-radius: $border-radius-large;
                border-bottom-right-radius: $border-radius-large;
                padding-bottom: 0.5rem;
            }
        }
    }
    .open > .dropdown-menu-lg {
        display: flex;
    }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
   }
   
   /* Firefox < 16 */
   @-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
   }
   
   /* Safari, Chrome and Opera > 12.1 */
   @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
   }
   
   /* Internet Explorer */
   @-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
   }

   .dropdown-menu-stretch {
       li {
           min-height: 48px;
           display: flex;
           align-items: center;
       }
   }