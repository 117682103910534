@import 'variables/screen';
@import 'variables/colours';
@import 'variables/colours';
@import 'variables/layout';
@import 'variables/navbar';
@import '_mixins';
@import 'variables/external-brands';

*[hidden] {
  display: none !important;
}

body {
  background-color: var(--body-bg);
  color: var(--gray-base);
  // Override ng1 padding
  padding: 0 !important;
  margin: 0 !important;
}

.master-detail {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  > * {
    min-width: 250px;

    &.master-detail__detail {
      flex-grow: 1;

      @media (min-width: $screen-lg-min) {
        flex-basis: 50%;
      }
    }
  }
}

// Angular 12 app only
@media (min-width: $screen-sm-min) {
  app-root .master-detail > .master-detail__detail {
    height: 100% !important;
  }
}

.modal {
  button {
    pointer-events: all;
  }

  .modal-body {
    padding: 2.5rem 1.5rem;
  }

  .modal-wizard {
    max-width: 1400px;
  }

  .modal-sm {
    max-width: 600px;
  }

  .modal-content {
    background-color: $white;
  }
}

// Inputs

input,
.form-control,
.match-form-control,
sb-date-range-picker div {
  background: $white;
  color: $gray-darker;
}

.pagination {
  li,
  .disabled {
    a {
      background: $white;

      &:disabled {
        background: $white;
      }
    }
  }
}

// button {
//     background: $gray-lighter;
// }

.btn.inactive {
  cursor: default;
}

.card {
  background: $white;
}

// Table

.table-striped > tbody > tr:nth-of-type(odd) {
  background: var(--table-bg-accent);
}

// Links

// a {
//     color: var(--gray-darker);
// }

// Img

img {
  filter: var(--theme-img-filter);
}

// Disabled area

.disabled-area {
  cursor: not-allowed;
}

// Backgrounds

.bg-primary {
  background-color: $brand-primary;
}

.bg-secondary {
  background-color: $brand-secondary;
}

.bg-success {
  background-color: $brand-success;
}

.bg-warning {
  background-color: $brand-warning;
}

.bg-danger {
  background-color: $brand-danger;
}

.bg-info {
  background-color: $brand-info;
}

// Border

.rounded {
  @include rounded;
}

// Migrated

--root {
  --app-height: 100vh;
}

.h-app {
  height: var(--app-height);
}

@media (min-width: $screen-sm-min) {
  header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    box-shadow: $box-shadow;

    > * {
      flex-grow: 1;
    }

    .btn {
      box-shadow: none;
    }

    i {
      font-size: 1.5rem;
    }
  }
}

.flush {
  margin: 0 !important;
}

.tight {
  padding: 0 !important;
}

body {
  background-color: $body-bg;

  &.messagingModule {
    background-color: $white;
  }

  &.dashboardModule {
    margin-bottom: 0;
    padding-bottom: 5rem;
  }

  @media (hover: hover) and (min-width: $sb-custom-navbar-min) {
    padding-top: $navbar-height;
  }
}

// .sb-bg-dark {
//     background-color: var(--solid-bg)!important;
//     background-image: $background-darken!important;
// }

// .sb-bg-light {
//     background-color: $brand-primary!important;
//     background-image: $background-lighten-9!important;
// }

// .sb-bg-primary-light {
//     background-color: $brand-primary!important;
//     background-image: $background-lighten-9!important;
// }

// .sb-bg-secondary-light {
//     background-color: $brand-secondary!important;
//     background-image: $background-lighten-9!important;
// }

// .bg-gradient-warning {
//     background: $brand-warning!important;
//     background-image: $bg-gradient-warning!important;
// }

// .bg-gradient-success {
//     background: $brand-success!important;
//     background-image: $bg-gradient-success!important;
// }

// .bg-gradient-info {
//     background: $brand-info!important;
//     background-image: $bg-gradient-info!important;
// }

// .bg-info-lighter {
//     background: $brand-info;
//     background-image: $background-lighten-95;
// }

.xs-column,
.xs-column-reverse {
  display: flex;
  flex-wrap: wrap;

  > * {
    padding: 0rem 1rem 0.5rem 0rem;
  }

  @media (max-width: $screen-xs-max) {
    justify-content: space-between;

    > * {
      padding: 1rem;
    }
  }

  sb-radio-button + label {
    padding: 0rem 0rem 0rem 1rem;
  }

  > .btn {
    padding: 1.2rem 0;
  }

  &.xs-column-autopadding {
    > * {
      @media (max-width: $screen-xs-max) {
        padding: 0rem 0rem 1.25rem 0rem;
      }
    }

    &.xs-column > *:last-child {
      padding: 0rem;
    }

    &.xs-column-reverse > *:first-child {
      padding: 0rem;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .xs-column {
    flex-direction: column;
  }

  .xs-column-reverse {
    flex-direction: column-reverse;
  }
}

.sb-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(min(250px, 100%)), 1fr));
  grid-gap: 1rem;

  .card {
    margin: 0;
  }

  &.sb-grid-lg {
    grid-template-columns: repeat(auto-fill, minmax(calc(min(385px, 100%)), 1fr));
  }

  &.sb-grid-xl {
    grid-template-columns: repeat(auto-fill, minmax(calc(min(500px, 100%)), 1fr));
  }

  @for $i from 1 through 10 {
    &.sb-grid-col-#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);

      @media (max-width: $screen-xs-max) {
        grid-template-columns: 1fr;
      }
    }
  }
}

.grid-gap {
  grid-gap: 3rem;
}

.nowrap {
  flex-wrap: nowrap !important;
}

// Colour borders

@mixin border-solid {
  border: solid 1px;
}

.border-primary {
  @include border-solid;
  border-color: $brand-primary;
}

.border-secondary {
  @include border-solid;
  border-color: $brand-secondary;
}

.border-success {
  @include border-solid;
  border-color: $brand-success;
}

.border-danger {
  @include border-solid;
  border-color: $brand-danger;
}

.border-warning {
  @include border-solid;
  border-color: $brand-warning;
}

.border-info {
  @include border-solid;
  border-color: $brand-info;
}

.border-neutral {
  @include border-solid;
  border-color: $gray-light;
}

.border-muted {
  @include border-solid;
  border-color: $gray;
}

.border-bottom {
  border-bottom: solid 1px $gray-lighter;
}

// Flex layouts

@mixin fill-vertical-space {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.flex {
  display: flex;
}

.align-start {
  display: flex;
  align-items: flex-start !important;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.alert > * {
    &:last-child {
      text-align: right;

      .btn {
        // Bit of a cheat to keep neat on mobile or whenever buttons wrap. Might be worth building something more responsive?
        margin: 0.5rem;
      }
    }
  }
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.align-center {
  display: flex;
  align-items: center;

  @media (max-width: $screen-xs-max) {
    &.xs-column,
    .xs-column-reverse {
      align-items: flex-start;
    }
  }
}

.align-end {
  display: flex;
  align-items: flex-end;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-50 {
  > * {
    flex-basis: 50%;
  }
}

.lg-justify-start {
  @media (min-width: $screen-lg-min) {
    display: flex !important;
    justify-content: flex-start !important;
  }
}

.flex-grow {
  flex-grow: 1;
}

.max-width-xs-device {
  max-width: $screen-xs-max;
}

.content-container {
  max-width: $content-width;
  margin: auto;
}

.fit-to-vh,
.fit-to-vh-2 {
  overflow: auto;
}

.fit-to-vh {
  max-height: calc(100vh - 250px);
}

.fit-to-vh-2 {
  max-height: calc(100vh - 170px);
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto-y-only {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.overflow-wrap-breakword {
  overflow-wrap: break-word;
}

.lowlight-area * {
  opacity: 0.75;
}

.bg-light-gray {
  background-color: $gray-light;
}

.width-fill-available {
  width: -webkit-fill-available;
}

.display-initial {
  display: initial;
}

.consent-status-button {
  width: 15% !important;
  min-width: 15% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.disabled-area {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.unclickable,
.unclickable * {
  pointer-events: none;
}

.fit-to-screen {
  max-width: 100vh;
  white-space: normal;
}

// Zendesk

iframe#launcher {
  z-index: 1040 !important;
}

// Negative margin

.mb-n3 {
  margin-bottom: -1rem;
}

// svg colouring

[fill='#6c63ff'],
[fill='#6C63FF'] {
  fill: var(--brand-secondary) !important;
}

body.mobile-app {
  .shell {
    padding-top: 0;
  }

  .content__outer {
    height: 100vh;
  }
}

// Banner

.banner {
  width: 100%;
  min-height: 63px;
  background: $white;
  padding: 1.5rem;
  margin: 2rem 0;
  box-shadow: $box-shadow;

  .collapsing {
    transition-timing-function: linear !important;
    transition-duration: 0.25s !important;
  }
}

// Position

.position-relative {
  position: relative;
}

// Border

.border-danger {
  border: solid 1px $brand-danger;
}

// Hover-enabled device

.visible-hover {
  @media (hover: none), (max-width: $sb-custom-navbar-hidden) {
    display: none !important;
  }
}

// Invisibility

.invisible {
  visibility: hidden;
}

// Highlighted content

.highlighted-content {
  border: 1px solid $border-color;
  border-radius: $border-radius-base;
  padding: 1rem;
}

// Page content

.page-content {
  padding: 15px;
  padding-bottom: 10px;
}

// Copied from https://github.com/twbs/bootstrap/blob/main/scss/mixins/_visually-hidden.scss
.visually-hidden {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;

  // Fix for positioned table caption that could become anonymous cells
  &:not(caption) {
    position: absolute !important;
  }
}
