@import './borders';
@import './spacing';

//== Forms
//
//##

//** `<input>` background color
$input-bg: #fff;
//** `<input disabled>` background color
$input-bg-disabled: $gray-lighter;

//** Text color for `<input>`s
$input-color: $text-color;
//** `<input>` border color
$input-border: #ccc;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: $border-radius-base;
//** Large `.form-control` border radius
$input-border-radius-large: $border-radius-large;
//** Small `.form-control` border radius
$input-border-radius-small: $border-radius-small;

//** Border color for inputs on focus
$input-border-focus: #66afe9;

//** Placeholder text color
$input-color-placeholder: #999;

//** Default `.form-control` height
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 2);
//** Large `.form-control` height
$input-height-large: (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2);
//** Small `.form-control` height
$input-height-small: (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2);

//** `.form-group` margin
$form-group-margin-bottom: $line-height-computed;

$legend-color: $gray-dark;
$legend-border-color: #e5e5e5;

//** Background color for textual input addons
$input-group-addon-bg: $gray-lighter;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border;

//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed;

// Custom

$form-font-size: 12px;
$input-border-color: #c4daf3;
$input-height: 2.375rem;
$input-height-sm: 1.9375rem;
$input-height-lg: 3rem;
$input-btn-focus-width: .2rem;
$custom-control-indicator-bg: #dee2e6;
$custom-control-indicator-disabled-bg: #e9ecef;
$custom-control-description-disabled-color: #868e96;