body {
     * {
        &::-webkit-scrollbar {
            width: 10px;
        }
        
        &::-webkit-scrollbar-track {
            background: $off-white; 
        }
        
        &::-webkit-scrollbar-thumb {
            background: $gray-light; 
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: $gray; 
        }
     }
}