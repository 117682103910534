﻿.text-light { color: $white; }
.text-dark { color: $text-color; }

.bg-dark { background: $gray-darker; }
.bg-muted { background: $gray-lighter; }

.link-light { 
    color: $white; 

    &:focus,
    &:hover {
        color: darken(#ffffff, 10%);
    }
}

.link-underlined { 
    text-decoration: underline; 

    &:focus,
    &:hover {
        text-decoration: none;
    }
}


.loading-icon {
    display: block;
    margin: $line-height-computed auto;
    width: 70px;
}