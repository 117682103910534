﻿.transport-configuration-box {
    border: 1px solid lightgrey;
    padding: 10px;
    display: inline-block;
    .table {
        width: auto;
    }
}

.timetable-routes-selection {
    max-height: 500px;
    overflow-y: auto;
}

.mt-minus-7 {
    margin-top: -7px;
}

/* Chrome, Safari, Edge, Opera */
.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.number-input {
    -moz-appearance: textfield;
}

.fal-with-text {
    font-weight: 300;
}

.mass-assignments-method-routes-table tr,
.mass-assignments-method-routes-table tr td {
    border: none !important;
    border-width: 0 !important;
    border-top: none !important;
}

.mass-assignments-method-routes-table tr.danger,
.mass-assignments-method-routes-table tr.danger td {
    background-color: #f2dede
}

.mass-assignments-method-routes-table tr.unselectable {
    background-color: lightgray;
    cursor:not-allowed;
}

.whitespace-nowrap
{
    white-space:nowrap;
}

.float-right
{
    float: right;
}

.ml-minus-3{
    margin-left: -3px;
}

.font-size-85pc{
    font-size: 85%;
}
