﻿$screen-no-underline: 1240px;
$maincontent-padding: 10px;

.buddy-nav {
    display: block;
    padding: $line-height-computed 0;
@media(hover: none), (max-width: $sb-custom-navbar-hidden) {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        align-items: center;
        height: 44px;
        &::-webkit-scrollbar {
            display: none;
        }
        .buddy-nav__item {
            padding-left: 1rem;
            margin-left: 0;
            // Second child after back button
            &:nth-child(2) {
                border: 0;
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0.75rem;
            }
            &::after {
                display: none;
            }
            .buddy-nav__link {
                border-radius: $border-radius-large;
                padding: 0.25rem 0.75rem;
                .buddy-nav__label {
                    margin: 0;
                    font-size: 1.25rem;
                }
            }
            &.buddy-nav__item--active {
                border-left: 0;
                margin-right: 0.5rem;
                .buddy-nav__link {
                    background: $white;
                    color: $brand-primary;
                    .buddy-nav__label { 
                        font-weight: $semi-bold;
                    }
                }
            }
            &.buddy-nav__item--active+.buddy-nav__item {
                border: 0;
            }
            .buddy-nav__icon {
                display: none;
            }
        }
    }
}

    .buddy-nav__item {
        position: relative;
        display: inline-block;
        vertical-align: bottom;
        padding: 0;
        margin: 0 $grid-gutter-width*0.5;
        transform: translateZ(0);

        @media(hover: hover) and (max-width: $screen-no-underline) {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            height: 4px;
            width: 100%;
            margin-top: 16px;
            background: $white;
            transform: scaleX(0);
            transition: transform 0.25s ease;
            @media(max-width: $screen-no-underline) {
                display: none;
            }
        }

        &:hover {
            &:after {
                transform: scaleX(1);
            }
        }
    }

        .buddy-nav__link {
            display: block;
            text-align: center;
            color: $white;

            &:active,
            &:focus,
            &:hover {
                text-decoration: none;
                color: $white;
            }
        }

            .buddy-nav__label {
                display: block;
                margin: 0 0 $line-height-computed*0.5;
                font-size: $font-size-small;
                line-height: 1;
            }

            .buddy-nav__count {
                display: block;
                font-size: $font-size-h1;
                line-height: 1;
            }

            .buddy-nav__icon {
                font-size: $font-size-h2;
            }


    .buddy-nav__item--active {

        @media(hover: hover) and (max-width: $screen-no-underline) {
            font-weight: $semi-bold;
        }

        &:after {
            transform: scaleX(1);
            
        }
    }

@media (max-width: $screen-sm) {

    .buddy-nav {
        padding: $line-height-computed*0.5 0;
    }

        .buddy-nav__item {

            &:after {
                margin-top: 6px;
            }
        }

            .buddy-nav__label {
                margin: 0 0 $line-height-computed*0.25;
            }

            .buddy-nav__count {
                font-size: $font-size-h3;
            }

            .buddy-nav__icon {
                font-size: $font-size-large;
            }
}

.buddy-nav--compact {
    @media (min-width: $screen-sm) and (hover: hover) {
        padding: $line-height-computed*0.5 $grid-gutter-width*0.25;
    }

    .buddy-nav__item {
        margin: 0 $grid-gutter-width*0.25;

        &:after {
            margin-top: 6px;
        }
    }

        .buddy-nav__label {
            margin: 0 0 $line-height-computed*0.25;
        }

        .buddy-nav__icon {
            font-size: $font-size-large;
        }
}

@media print {
    .buddy-nav {
        display: none;
    }
}

$sidemenu-full-width: 260px;
@media (hover: hover) and (min-width: $sb-custom-navbar-min) {
    section > *[ui-view] > sub-menu, section > *[ui-view] > * > sub-menu, .ui-container > sub-menu {
        position: fixed;
        left: 0;
        top: $navbar-height;
        z-index: 1029;
        ul.buddy-nav {
            height: calc(100vh - #{$navbar-height});
            padding: 0;
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            width: $navbar-height;
            transition: width ease 0.25s;
            overflow: hidden;
            overflow-y: auto;
            background-color: $gray-dark;
            box-shadow: $box-shadow;
            &::-webkit-scrollbar {
                display: none;
            }
            .buddy-nav__item {
                margin: 0;
                padding: 0.33rem 0rem;
                &:after {
                    display: none;
                }
                .buddy-nav__link {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    align-items: center;
                    overflow: hidden;
                    padding: 1.2rem;
                    height: 40px;
                    width: 100%;
                    .buddy-nav__label {
                        visibility: hidden;
                        margin: 0;
                        font-size: 1.25rem;     
                        opacity: 0;
                        transition: opacity 0.25s ease;
                        transition-delay: 0.15s;
                        line-height: 1.75rem;
                        text-align: left;
                    }
                    .buddy-nav__icon {
                        margin-right: 25px;
                        width: 2rem;
                        font-size: 1.75rem;
                        padding-left: 1rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    &:hover {
                        background-color: $gray-darker;
                    }
                }
                &.buddy-nav__item--active {
                    .buddy-nav__link, .buddy-nav__link:hover {
                        background-color: $brand-primary;
                    }
                }
            }
            &:hover {
                width: $sidemenu-full-width;
                .buddy-nav__link {
                    .buddy-nav__label {
                        opacity: 1;
                        visibility: visible;
                    }
                }
                .buddy-nav__pin {
                    visibility: visible;
                }
            }
            &.buddy-nav-open {
                width: $sidemenu-full-width;
            }
            &.sub-menu-bar-inner-modules {
                display: flex;
            }
        }
        .buddy-nav__pin {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            font-size: 1.5rem;
            color: $brand-secondary;
            visibility: hidden;
            transition: visibility 0.25s ease-in;
        }
        &+ ui-view > *, &+ *[ui-view], &+ * ui-view {
            display: block;
            padding-left: $navbar-height + $maincontent-padding;
            padding-right: $maincontent-padding;
            .buddy-nav__pin {
                display: none;
            }
            ui-view > *, *[ui-view], * ui-view {
                padding-left: 0;
            }
        }
    }
    .profileModule {
        section > *[ui-view] > sub-menu {
            ul.buddy-nav {
                width: $sidemenu-full-width;
                .buddy-nav__item .buddy-nav__link .buddy-nav__label {
                    visibility: visible;
                    opacity: 1;
                }
            }
            + div[ui-view] {
                padding-left: $sidemenu-full-width;
            }
        }
    }
}
@media (hover: hover) and (min-width: $sb-custom-navbar){
    .main-view {
        padding-left: $navbar-height;
    }
}
.profileModule {
    .main-view {
        padding-left: 0;
    }
    diary-event-list {
        sub-menu {
            display: none;
        }
    }
}
.card-detail .main-view {
    padding-left: 0;
}

sb-summary + sub-menu .buddy-nav__back, .card-detail .buddy-nav__back, .modal .buddy-nav__back {
    display: none;
}

sb-summary.sb-summary-hover + sub-menu .buddy-nav__back {
    display: block;
}