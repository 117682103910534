.http-error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem;
    h2 {
        color: $gray;
        margin-top: 1rem;
        font-style: italic;
    }
    img {
        max-height: 40rem;
        margin: 2rem;
    }
    button {
        margin-top: 5rem;
    }
    a {
        margin-top: 1rem;
    }
}