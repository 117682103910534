﻿$isams-btn-colour: #43a4dc;

body.logged-out {
    background-size: cover;
    background-position: top;
    header {
        background: transparent;
        box-shadow: none;
        img {
            display: none;
        }
    }
}

.validation-summary-errors {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 2px;
    text-align: center;
}

.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    .login-form {
        width: 100%;
        max-width: 350px;
        padding: 1.5rem;
        padding-top: 70px;
        position: relative;
        margin-top: 80px;
        margin-bottom: 80px;
        .login-form__school-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 1px;
            position: absolute;
            left: 50%;
            top: -50px;
            background: $white;
            border: 2px solid #fff;
            box-shadow: 0 0 10px rgba(0,0,0,.1);
            transform: translateX(-50%);
            padding: 1rem;
            img {
                max-height: 75px;
                max-width: 250px;
            }
        }
        .login-form__school-name {
            padding: 2rem;
            margin-top: 25px;
        }
    }

    .login-header {
        position: relative;
        text-align: center;
        button {
            position: absolute;
            left: 0;
            top: 5px;
        }
    }
    .login-methods {
        padding: 10px 0;
        > div {
            display: grid;
            grid-gap: 0.75rem;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            button {
                padding: 0.75rem;
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;
                border: solid 2px;
                border-radius: 25px;
                font-size: 1.4rem;
                font-weight: $semi-bold;
                &:hover {
                    opacity: $hover-opacity;
                }
                @each $name, $colour in $ssoProviders {
                    &.btn-#{$name} {
                        color: $colour;
                    }
                }
                img {
                    height: 20px;
                    width: 20px;
                    margin-right: 1rem;
                }
            }
        }

        .login-separator {
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: solid 1px #e3e3e3;
            margin-top: 35px;
            height: 25px;
            position: relative;
            text-transform: uppercase;
            div {
                margin-bottom: 25px;
                padding: 15px;
                background-color: $white;
            }
        }

    }
    .register-form {
        margin-top: 25px;
        width: 100%;
        max-width: 700px;
    }
}

.modal-isams {
    .modal-header {
        text-align: center;
        img {
            max-height: 60px;
        }
    }
    .modal-body {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 2rem 0;
        button {
            font-size: 1.4rem;
            color: $isams-btn-colour;
            border: solid 2px;
            border-radius: 50%;
            background-color: transparent;
            width: 90px;
            height: 90px;
            div {
                text-align: center;
                i {
                    font-size: 3rem;
                    margin-bottom: 10px;
                }
            }
            &:hover {
                opacity: $hover-opacity;
            }
        }
    }
}
