﻿.modal-content {
    border: 0;
}

.modal-header, .modal-header.title-bar {
    color: #ffffff;
    margin: 0 0 1.5rem 0 ;
    border:0;
    width: 100%;
    font-weight: $semi-bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 1.5rem;
    @extend .sb-bg-dark;
    .fa-times {
        font-size: 2rem;
    }
    &.modal-header-danger {
        background-color: $brand-danger!important;
    }
    @each $name, $colour in $ssoProviders {
        &.modal-header-#{$name} {
            background-color: $colour!important;
        }
    }
    // .modal-title no longer used but available if not using sbModalHeader
    .modal-title {
        justify-content: space-between;
        display: flex;
        align-items: center;
    }
    .btn-link {
        color: #ffffff;
        padding: 0.5rem;
    }
    > h3 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        > * {
            margin-right: 0.5rem;
            &:last-child {
                margin-right: 0;
            }
            &.badge {
                margin-left: 1rem;
            }
        }
    }
}

.modal-footer .align-left {
    text-align:left;
}

.modal-footer-space-between {
    @extend .p-4, .space-between;
}

.modal-scroll {
    .modal-body {
        overflow-y: auto;
        max-height: calc(100vh - 200px);
        padding-right: 10px;
    }
}

@media(max-width: $screen-xs-max) {
    .modal-body .container-fluid {
        padding: 0;
    }
}

.modal-body-tall {
    min-height: 450px;
}

// Modal-only view

body.modal-only {
    &:before {
        content: 'Loading...';
        display: flex;
        height: 100vh;
        width: 100vw;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
    }
    > * {
        display: none;
    }
    .modal {
        display: block;
        .modal-dialog {
            margin: 0;
            width: 100vw;
            max-width: none;
            height: var(--app-height);
            transform: none;

            .modal-header {
                display:none;
            }

            // Target modal-content, or it's component child if using component
            .modal-content, .modal-content > *:only-child {
                border-radius: 0;
                box-shadow: none;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100vh;
                padding: 1rem;

                .modal-body {
                    max-height: none;
                    height: 100%;
                    padding: 0.75rem;
                    // Handle case of footer inside body
                    .modal-footer {
                        padding: 0.75rem 0 0 0!important;
                    }
                }
                .modal-footer {
                    width: 100%;
                    background: $white;
                    padding: 1.2rem 0 0 0!important;
                    z-index: 1000;
                    padding: 0.75rem!important;
                    * {
                        width: 100%;
                    }
                    .btn, sb-submit-button {
                        margin: 0;
                        display: block;
                        margin-top: 1rem;
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                    .btn.hidden-modal-only+.btn {
                        margin: 0!important;
                    }
                }
            }
        }
    }
    .hidden-modal-only {
        display: none!important;
    }
}

.tuitionFeesModule.modal-open {
    overflow: inherit;
  }
