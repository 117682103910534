$navbar-space: $navbar-height + 1;

.page-loader {
    width: 100vw;
    height: calc(100vh - #{$navbar-space});
    margin-top: $navbar-space + 2;
    background: $white;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 100; // Needs to be on top of page content but under menu tooltips
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}