.payment-cards {
    background: $white;
    padding: 0;
    margin-bottom: 2rem;
    &.payment-card-payment-mode {
        background: transparent;
    }
    .payment-date {
        padding: 2.5rem 1rem 0 2rem;
        font-weight: $semi-bold;
        color: $gray;
        border-top: solid 1px $gray-lighter;
    }
    .space-between {
        margin: 0 1rem;
        padding: 2.5rem 0.75rem;
    }
    .payment-item {
        border-bottom: solid 1px $gray-lighter;
        > div {
            &:first-child {
                flex-basis: 80%;
                h3 {
                    font-weight: $semi-bold;
                    text-transform: uppercase;
                    font-size: 1.3rem;
                }
            }
            &:last-child {
                white-space: nowrap;
                font-size: 1.5rem;
                padding-left: 1.5rem;
                @extend .text-primary-dark;
            }
        }
    }
    > div > div:last-child .space-between {
        border-bottom: none;
    }
    .card-style {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0;
        position: relative;
        margin-bottom: 25px;
        @media (max-width: $screen-sm-max) {
            flex-direction: column;   
        }
        > div {
            width: 100%;
            &:first-child {
                flex-basis: 60%;
                display: flex;
                flex-direction: column;
                > div {
                    padding: 1rem;
                    &:last-child {
                        display: flex;
                        flex-direction: row;
                        > div {
                            display: flex;
                            align-items: center;
                            sb-avatar {
                                margin-right: 1rem;
                            }
                            &:first-child {
                                margin: 0 3rem 0 0;
                            }
                        }
                        @media (max-width: $screen-sm-max) {
                            flex-direction: column;
                            > div:first-child {
                                margin: 0 0 1.5rem 0;
                            }
                        }
                    }
                }
            }
            &:nth-child(2) {    
                flex-basis: 40%; 
                @media (max-width: $screen-sm-max) {
                    border-top: solid 1px $gray-light;
                    margin-top: 1.5rem;
                    background-color: $almost-white;
                }
                > div {
                    &:first-child {
                        font-size: 1.5rem;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        flex: 0 1 0;
                        > div {
                            > div {
                                display: flex;
                            }
                        }
                        i {
                            margin-left: 0.5rem;
                        }
                        .adjustment-note {                           
                            grid-column-start: 1;
                            grid-column-end: 4;
                            font-size: 1.2rem;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            padding-top: 0.5rem;
                        }
                    }
                    &.related-payments {
                        > div {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 2rem;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            > div {
                                &:first-child {
                                    flex-basis: 80%;
                                }
                            }
                        }
                    }
                }
            }
            &:last-child {
                flex: 0 1 0;
                padding: 2rem;
                display: flex;
                @media (max-width: $screen-sm-max) {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 1rem;
                    width: auto;
                }
                input[type=checkbox] {
                    height: 2rem;
                    width: 2rem;
                    margin: 0;
                }
            }
            > div {
                padding: 2rem;
            }
        }
    }
}

    .alert-payment {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media (max-width: $screen-xs-max) {
            flex-direction: column;
            > *:first-child {
                padding: 0;
            }
            h2 {
                margin-bottom: 1.5rem;
            }
        }
    }

    .payment-accepted-info {
        max-height: 40px;
        max-width: 75%;
        object-fit: contain;
        margin-top: 1.5rem;
    }