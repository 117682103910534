// Toast
$alert-bg-lighten: 32%;
$alert-bg-lighten-warning: 48%;
$alert-bg-lighten-extra: 48%;
.title-bar, sb-title-bar, .title-bar__container{
    ~ .alert, ~ sb-alert > .alert {
        margin-top: 2rem;
    }
}
.alert {
    padding: 2rem;
    box-shadow: $box-shadow;
    border-left: solid 0.25rem;
    min-width: 25vw;
    background: lighten($brand-info, $alert-bg-lighten);
    border-left-color: $brand-info;
    color: #444444;

    @media screen and (min-width: 1880px) {
        min-width: 470px;
    }

    &.alert-dismissible {
        padding-right: 35px;
    }

    > span, > p {
        width: 100%;
        font-size: 1.35rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &::before {
            font-family: "Font Awesome 5 Pro";
            margin-right: 2.5rem;
            font-size: 2rem;
            font-weight: 900;
        }
    }

    &.alert-dismissible .btn {
        position: absolute;
        right: 1rem;
        top: 0.25rem;
        font-size: 2.5rem;
        opacity: 9;
    }

    &.alert-success {
        background: lighten($brand-success, $alert-bg-lighten-extra) !important;
        border-left-color: $brand-success;

        > span::before, > p::before {
            color: $brand-success;
            content: '\f058';
        }

        [class='btn'] {
            color: $brand-success;
        }
    }

    &.alert-danger {
        background: lighten($brand-danger, $alert-bg-lighten);
        border-left-color: $brand-danger;

        > span::before, > p::before {
            color: $brand-danger;
            content: '\f06a';
        }

        [class='btn'] {
            color: $brand-danger;
        }
    }
    &.alert-warning {
        background: lighten($brand-warning, $alert-bg-lighten-warning);
        border-left-color: $brand-warning;
        > span::before, > p::before {
            color: $brand-warning;
            content: '\f071';
        }
        [class='btn']{
            color: $brand-warning;
        }
    }
    &.alert-info {
        background: lighten($brand-info, $alert-bg-lighten);
        border-left-color: $brand-info;

        > span::before, > p::before {
            color: $brand-info;
            content: '\f05a';
        }

        [class='btn'] {
            color: $brand-info;
        }
    }

    button {
        margin-top: 1.5rem;
    }

    [class='btn'] {
        border: solid 1px;
        background: transparent;
        filter: grayscale(0.5);
    }

    sb-button, sb-submit-button {
        ~ sb-button, ~ sb-submit-button {
            .btn {
                margin-left: 1rem;
            }
        }
    }

    .alert-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .alert-body__note {
            text-align: right;
            color: $gray;
            font-style: italic;
            font-size: 1.2rem;
            padding-top: 1.5rem;
            align-self: flex-end;
        }
    }
}
.ng-toast__message {
    &.toast-email {
        .alert-info {
            > span::before, > p::before {
                content: '\f0e0';
            }
        }
    }
    &.toast-chat {
        .alert-info {
            > span::before {
                content: '\f007';
            }
        }
    }
    &.toast-delete {
        .alert-success {
            > span::before, > p::before {
                content: '\f1f8';
            }
        }
    }
    button {
        margin-top: auto;
    }
}
