﻿div.cke {

    border: 1px solid #ccc;
    border-radius: $border-radius-base;
    margin-bottom: $line-height-computed;
    overflow: hidden;

    * {
        box-sizing: content-box;
    }

    .cke_inner {
        border-radius: $border-radius-base;
    }

    .cke_top {
        border-bottom: 1px solid #ccc;
    }
}