// Scale up the modal

  @media (min-width: $screen-md-min) {
    .modal-xl { 
      width: $modal-lg;
      margin: auto;
      margin-top: 2rem;
    }
  }

  @media (min-width: $screen-lg-min) {
    .modal-xl { width: $modal-xl; }
  }

  .modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
  }