﻿.breadcrumb {
    /*margin: 0;
    background: none;
    color: $gray-light;*/

    > li {
        + li:before {
            padding: 0 2px 0 5px;
        }

        > a {
            /*font-weight: 300;
            color: $gray-light;*/
        }

        &:last-child {

            > a {
                /*color: $text-color;*/
                font-weight: 600;
            }
        }
    }
}