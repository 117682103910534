﻿// wrappers
.k-dropdown-wrap,
.k-picker-wrap {
    transition: background-color 0.25s ease;
}

// inputs
.k-autocomplete, .k-combobox, .k-datepicker, .k-timepicker, .k-datetimepicker, .k-colorpicker, .k-numerictextbox, .k-dropdown, .k-selectbox, .k-textbox, .k-toolbar .k-split-button {
    width: 100% !important;
}

//datepicker 
.k-datepicker {

    border-radius: $border-radius-base;
    overflow: hidden;

    .k-picker-wrap {

        border-radius: $border-radius-base;
        overflow: hidden;

        &:before {
            height: 31px;
            padding: 0;
        }

        .k-input {
            height: 31px;
            line-height: 31px;
            padding: 0;
            border-radius: $border-radius-base;
            overflow: hidden;
        }
    }
}

// modal
div.k-window-content {
    padding: $line-height-computed $grid-gutter-width*0.5;

    * {
        box-sizing: border-box;

        .k-widget,
        .k-widget * {
            box-sizing: content-box;
        }
    }
}

.k-window-titlebar {
    position: static;
    box-sizing: border-box;
    height: auto;
    padding: $line-height-computed*0.5 $grid-gutter-width*0.5;

    .k-window-title {
        position: static;
    }

    .k-window-actions {
        padding: $line-height-computed*0.5 $grid-gutter-width*0.5;
        top: 0;
        right: 0;
    }
}