@use "sass:math";

.filter-form {
    @extend .clearfix;
    display: inline-block;
    margin-right: math.div($grid-gutter-width, -4);

    // location dependancy hack
    .btn,
    .k-picker-wrap {
        border: 0;
    }
}

    .filter-form__group {
        @extend .clearfix;
        display: inline-block;
        vertical-align: middle;
        margin-right: $grid-gutter-width*0.25;
    }

        .filter-form__label {
            display: inline-block;
            vertical-align: middle;
            font-weight: normal;
        }

        .filter-form__input {
            display: inline-block;
            vertical-align: middle;
            width: 127px;
        }

.filter-form--spaced {
    margin-right: 0;
}

@media (max-width: $screen-sm) {

    .filter-form {
        display: block;
    }

        .filter-form__group {
            display: block;
            margin: 0 0 $line-height-computed*0.5;
        }

            .filter-form__label {
                display: block;
                float: left;
                width: 25%;
                padding: 5px $grid-gutter-width 0 0;
                text-align: right;
            }

            .filter-form__input {
                display: block;
                float: left;
                width: 75%;
            }
}