// Colours

.text-base {
    color: $text-color;
}

.text-gold {
    color: $brand-gold;
}

.text-android {
    color: #a4c639;
}

.text-iphone {
    color: #4E5851;
}

a {
    color: $text-color;
    &:focus, &:hover {
        color: $gray-darker;
    }
}

// File types

.file-pdf {
    color: #fa0f00;
}

.file-excel {
    color: #217346;
}

// Migrated from sb-typography

.pointer {
    cursor:pointer;
}
.pointer-highlight:hover {
    opacity: $hover-opacity;
}
.bold {
    font-weight: bold;
}
.semi-bold {
    font-weight: $semi-bold;
}
.underline {
    text-decoration: underline;
}
.line-through {
    text-decoration: line-through;
}
.lowercase {
    text-transform: lowercase;
}
.uppercase {
    text-transform: uppercase;
}
.capitalize {
    text-transform: capitalize ;
}
.normal {
    font-weight: normal;
}
.italicise {
    font-style: italic;
}
.text-in-flight {
    color: $brand-success;
}

.text-light,
.text-dark {
    a {
        color: inherit;
    }
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}

.v-align-top {
    vertical-align: top;
}

.v-align-sub {
    vertical-align: sub;
}

.v-align-sup {
    vertical-align: super;
}

.lh-1-point-5 {
    line-height: $line-height-computed * 1.5;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.text-primary {
    color: $brand-primary;
}

.text-secondary {
    color: $brand-secondary;
}

.text-primary-dark {
    color: $brand-primary;
    filter: $filter-darken;
}

.text-success, .text-success:hover {
    color: $brand-success;
}

.text-danger, .text-danger:hover {
    color: $brand-danger;
}

.text-warning, .text-warning:hover {
    color: $brand-warning;
}

.text-info, .text-info:hover {
    color: $brand-info;
}
.text-success-light, .text-success-light:hover {
    color: $brand-success;
}

.text-danger-light, .text-danger-light:hover {
    color: $brand-danger;
}

.text-warning-light, .text-warning-light:hover {
    color: $brand-warning;
}

.text-info-light, .text-info-light:hover {
    color: $brand-info;
}

.word-break-word {
    word-break: break-word;
}