// Styles ignored by git, need to import existing styles after updating them
  @import './styles/_variables.scss';
  @import './styles/_mixins.scss';
  @import './styles/thirdparty/thirdparty.scss';
  @import '../../../../Content/site';
  @import './styles/_ng1-overrides.scss';
  @import './styles/_ui-grid.scss'; // /Content/ui-grid.scss cannot resolve
  @import './styles/thirdparty/thirdparty.scss';
  @import './styles/thirdparty-overrides/thirdyparty-overrides.scss';
  @import './styles/_generic.scss';
  @import './styles/_faria-alignment.scss';
  @import './styles/global/global.scss';
  @import './styles/tables';
  @import './styles/variables/colours';

/* Importing Bootstrap SCSS file. */
/* Disabling until we migrate fully to Angular and ngBootstrap as clashes with our existing SCSS */