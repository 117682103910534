﻿@use "sass:math";

.list-nav {
    list-style: none;
}

    .list-nav__item {
        display: block;
        margin: 0 auto;
    }

        .list-nav__link {
            display: block;
            position: relative;
            padding: $line-height-computed*0.5 0;
            text-decoration: none;

            color: $gray;
            cursor: pointer;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                transition: background-color 0.25s ease;
                margin: 0 math.div($grid-gutter-width, -4);
                z-index: -1;
            }

            &:focus,
            &:hover {
                text-decoration: none;
                color: $gray;

                &:before {
                    background: $gray-lighter;
                }
            }

            /* This should ideally be 

            .list-nav__item--active {
                .list-nav__link {
                    color: $text-color;
                    font-weight: 600;
                }
            }

            but the '.active' class is to ingrained in the JS so requires bigger change */
            &.list-nav__item--active,
            &.active {
                color: $text-color;
                font-weight: 600;
            }
        }
