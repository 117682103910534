﻿[data-bs-toggle="collapse"], [data-toggle="collapse"] {
    cursor: pointer;

    &:before {
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        content: "\e259";
        transition: transform 0.25s ease;
        font-size: 75%;
        margin: 0 $grid-gutter-width*0.125 0 0;
        vertical-align: baseline;
    }

    &.no-icon {
        &:before {
            display: none;
        }
    }

    & > .collapsed-text {
        display: initial;
    }

    & > .open-text {
        display: none;
    }

    &[aria-expanded="true"] {

        & > .open-text {
            display: initial;
        }

        & > .collapsed-text {
            display: none;
        }
    }

    &[aria-expanded="true"],
    &.collapsed {
        &:before {
            transform: rotate(-90deg);
        }
    }

    &[aria-expanded="true"]:before {
            transform: rotate(0deg);
    }
}

button.navbar-toggle.collapsed {
    &:before {
        content: unset
    }
}
