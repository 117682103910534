﻿$sb-font-path: "/Fonts";

.mt-10
{
    margin-top: 4rem !important;
}

.mr-10
{
    margin-right: 4rem !important;
}

.mb-10
{
    margin-bottom: 4rem !important;
}

.ml-10
{
    margin-left: 4rem !important;
}

.mt-10-all
{
    margin: 4rem 4rem 4rem 4rem !important;
}

$size-3: 1rem;

// Bottom offset to move components above any support widget.
// 20px for when no support widget shown.
$sb-above-support-widget: 20px;