﻿.summary {
    @extend .clearfix;
    &.summary-static {
        position: relative;
        box-shadow: none;
        border-bottom: 0;
        z-index: 0;
    }
}

sb-summary + sub-menu {
    @extend .sb-bg-dark;
}

@media (hover: hover) and (min-width: $screen-sm) {
    sb-summary.sb-summary-hover {
        > .summary {
            position: sticky;
            width: calc(100% - 55px);
            left: 55px;
            z-index: 1000;
            background-color: none;
            .summary__body {
                background-image: $bg-shaded;
            }
        }
    }
}



.summary__body {
    font-size: 1.3rem;
    position: relative;
    overflow: hidden; // overflow clear hack
    padding: $grid-gutter-width*0.5;
    padding-bottom: 0.5rem;
    p {
        margin: 1rem 0rem;
    }
}

.summary__inner {
    display: flex;
    align-items: top;
    .summary__media {
        margin: 0rem 2rem 1rem 1rem;
        p {
            margin: 0.5rem 0rem;
        }
    }
    .summary__info {
        > * {
            display: flex;
            align-items: center;
            > * {
                display: flex;
                align-items: center;
                margin-right: 1.75rem;
                margin-bottom: 0.75rem;
                i {
                    margin-right: 0.5rem;
                    font-size: 1.4rem;
                    width: 2rem;
                    text-align: center;
                }
            }
        }
    }
    @media(max-width: screen-xs) {
        flex-direction: column;
    }
}


.summary__button {
    position: absolute;
    top: 0;
    right: 0;
}

.summary__actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.2rem;
}

@media (max-width: $screen-xs) {
    .summary__media {
        float: none;
        margin-bottom: $line-height-computed*0.5;
    }

    .summary__button {
        position: static;
        margin-top: $line-height-computed*0.5;
    }
}
