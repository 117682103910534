

.btn-social {
    &.btn {
        line-height: 24px;
    }

    > :first-child {
        line-height: 28px;
    }
}
