﻿.attendance {
    font-family: Arial, Helvetica, sans-serif;

    .ti-close, 
    .ti-check {
        font-size: 1.2em;
        font-weight: bolder;
    }

    .ti-close {
        color: #f00;
    }

    .ti-check {
        color: #0f0;
    }
    td {
        overflow: hidden;
    }
}
