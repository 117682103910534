﻿/* Fonts */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);

/* Global variables */
@import "Base/variables";

/* Global overrides for third party library defaults - Must be BEFORE libraries are imported to make use of !default overrides */
@import "ThirdPartyOverrides/bootstrap/sb-variables";

/* Third party libraries */
@import "ThirdParty/bootstrap";

@import "ThirdParty/themify";
@import "ThirdParty/sortable/sortable-theme-bootstrap.sass";

/* Global element modifications */
@import "Base/elements";

/* Global overrides for third party library components */
@import "ThirdPartyOverrides/bootstrap/sb-type";
@import "ThirdPartyOverrides/bootstrap/sb-typography";
@import "ThirdPartyOverrides/bootstrap/sb-buttons";
@import "ThirdPartyOverrides/bootstrap/sb-dimensions";
@import "ThirdPartyOverrides/bootstrap/sb-grid";
@import "ThirdPartyOverrides/bootstrap/sb-tables";
@import "ThirdPartyOverrides/bootstrap/sb-table-register";
@import "ThirdPartyOverrides/bootstrap/sb-labels";
@import "ThirdPartyOverrides/bootstrap/sb-breadcrumbs";
@import "ThirdPartyOverrides/bootstrap/sb-forms";
@import "ThirdPartyOverrides/bootstrap/sb-badge";
@import "ThirdPartyOverrides/bootstrap/sb-angular-tab";
@import "ThirdPartyOverrides/bootstrap/sb-collapse";
@import "ThirdPartyOverrides/bootstrap/sb-lists";
@import "ThirdPartyOverrides/bootstrap/sb-modal";
@import "ThirdPartyOverrides/bootstrap/sb-panels";
@import "ThirdPartyOverrides/bootstrap/sb-switches";
@import "ThirdPartyOverrides/bootstrap/input-groups";
@import "ThirdPartyOverrides/bootstrap/responsive-utilities";
@import "ThirdPartyOverrides/bootstrap/sb-uib-datepicker";
@import "ThirdPartyOverrides/bootstrap/borders";
@import "ThirdPartyOverrides/bootstrap/utilities/spacing";
@import "ThirdPartyOverrides/kendo/sb-kendo";
@import "ThirdPartyOverrides/ckeditor/sb-ckeditor";
@import "ThirdPartyOverrides/tinymce/font-awesome-icons";
@import "ThirdPartyOverrides/bootstrap/sb-mixins";
@import "ThirdPartyOverrides/bootstrap/sb-rtl";
@import "ThirdPartyOverrides/bootstrap/sb-social";
@import "ThirdPartyOverrides/bootstrap/sb-dropdown-menu";
@import "ThirdPartyOverrides/bootstrap/sb-spacing";
@import "ThirdPartyOverrides/bootstrap/sb-tiles-grid";
@import "ThirdPartyOverrides/bootstrap/sb-scrollbars";
@import "ThirdPartyOverrides/bootstrap/sb-diary";
@import "ThirdPartyOverrides/bootstrap/sb-card";
@import "ThirdPartyOverrides/bootstrap/sb-payments";
@import "ThirdPartyOverrides/bootstrap/sb-icons";
@import "ThirdPartyOverrides/bootstrap/sb-progress-bars";
@import "ThirdPartyOverrides/bootstrap/sb-timeline";
@import "ThirdPartyOverrides/bootstrap/sb-modals";
@import "ThirdPartyOverrides/bootstrap/sb-downloader";
@import "ThirdPartyOverrides/bootstrap/sb-alerts";
@import "ThirdPartyOverrides/bootstrap/sb-stamps";
@import "ThirdPartyOverrides/bootstrap/sb-settings";
@import "ThirdPartyOverrides/bootstrap/sb-responsive-utilities";

@import "ThirdPartyOverrides/ngToast/sb-ngToast";

/* Legacy CSS - will be removed gradually */
@import "Base/legacy";

/* Components */
@import "Components/animate";
@import "Components/layout";
@import "Components/login";
@import "Components/sticky";
@import "Components/sticky-footer";
@import "Components/buddy-nav";
@import "Components/list-nav";
@import "Components/title-bar";
@import "Components/listing";
@import "Components/summary";
@import "Components/chat-message";
@import "Components/category-icon";
@import "Components/filter-form";
@import "Components/news-article";
@import "Components/planner-checkbox";
@import "Components/planner-radiobutton";
@import "Components/wysiwyg";
@import "Components/platform-usage";
@import "Components/pagination";
@import "Components/cca";
@import "Components/attendance";
@import "Components/icons";
@import "Components/message-center";
@import "Components/dynamic-height";
@import "Components/fake-select";
@import "Components/dashboard";
@import "Components/multi-select";
@import "Components/balance-summary-widget";
@import "Components/action-heading";
@import "Components/list-subheading";
@import "Components/visual-select";
@import "Components/daterangepicker";
@import "Components/search-filter";
@import "Components/detail";
@import "Components/avatar";
@import "Components/http-error-message";
@import "Components/queue-run-button";

@import "Components/admin-main-settings";
@import "Components/transport-configuration";
@import "Components/tooltips";
@import "Components/side-menu-nav";
@import "Components/page-loader";
@import "Components/action-menu";
@import "Components/twilio-video";


/* Helpers */
@import "Helpers/rhythm";
@import "Helpers/gutters";
@import "Helpers/typography";
@import "Helpers/animation";
@import "Helpers/decoration";
@import "Helpers/loaders";

/* set default for printing */
@page { size: landscape; }
