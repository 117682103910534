﻿html {
    position: relative;
    min-height: 100%;
}

body {
    margin: 0 0 $line-height-computed*4.5; /* bottom = footer height + footer margin */
}

.sticky-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    //height: $line-height-computed*3.5;
    width: 100%;
    padding: $line-height-computed*0.25 0;
    margin-top: $line-height-computed;
}

@media print {
    .sticky-footer { display:none; }
}