﻿$dashboard-widget-height: 370px;

.dashboard-item {
    .title-bar {
        border-bottom: 0;
        color: $gray-dark;
        padding: 15px 0px;
        margin: 0rem 1rem;
    }
    a:hover, .btn:hover, .message-center {
        opacity: $hover-opacity;;
    }
    &:empty {
        display: none!important;
    }
    .tooltip-fake { 
        right: 100%;
    }
}

.welcome-message {
    position: relative;
    margin-bottom: 2rem;
    .welcome-message__close {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        font-weight: $semi-bold;
    }
}

$diary-date-width: 64px;
.dashboard-diary {
    position: relative;
    > div {
        position: relative;
        &:before {
            content: ' ';
            height: 100%;
            border-left: solid 1px $gray-light;
            position: absolute;
            left: $diary-date-width * 0.5;
            top: 0;
            bottom: 0;
            z-index: 0;
        }
        > div {
            display: grid;
            grid-template-columns: $diary-date-width auto;
            > div {
                &:first-child {
                    z-index: 1;
                    > div {
                        padding: 1rem;
                        text-align: center;
                        background: $white;
                    }
                }
                &:last-child {
                    padding: 1rem;       
                    border-radius: $border-radius-large;
                    margin: 1rem;
                    &.dashboard-diary__next-event {
                        background: $brand-primary;
                        background-image: $background-lighten;
                        color: $white;
                        .text-muted {
                            color: $gray-lighter;
                        }
                        .row-primary {
                            border-color: $brand-secondary;
                        }
                    }
                    &:hover {
                        opacity: $hover-opacity;;
                    }
                    > div {
                        &:last-child {
                            > div {
                                > * {
                                margin-bottom: 0.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .alert {
        display: inline-block;
        padding: 0.25rem 0.5rem;
        border-radius: 2px;
        margin-bottom: 1rem;
    }
}

.widget-icon {
    width: 4.2rem;
    height: 4.2rem;
    padding: 1rem;
    border-radius: 50%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    i {
        font-size: 2rem;
        margin: 0;
    }  
}

.widget-item-heading {
    padding: 0 0 0.5rem 0;
    width: 100%;
    text-align: left;
    font-size: 1.4rem;
    font-weight: $semi-bold;
    color: $gray;
    text-transform: uppercase;
}

// Dashboard grid
$dashboard-widget-width: 450px;
.dashboard-widgets {
    display: grid;
    grid-gap: 3rem;
    padding: 0 1rem 5rem 1rem;
    @media(min-width: $screen-sm-min) {
        grid-template-columns: 1fr $dashboard-widget-width;
    }
    .dashboard-widgets__top {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
    .dashboard-widgets__updates {
        display: grid;
        grid-gap: 2rem;
        @media(min-width: $screen-sm-min) {
            grid-template-columns: repeat(auto-fit, minmax($dashboard-widget-width, 1fr));
        }
        > * {
            @include fill-vertical-space;
            > *:last-child {
                @include fill-vertical-space;
                // Diary content has extra levels as reuses component
                #diary-scroll-area {
                    @include fill-vertical-space;
                    .dashboard-item-content {
                        @include fill-vertical-space;
                        > .card-list{
                            flex-grow: 1;
                        }
                    }
                }
            }
        }
        .card-list {
            flex-grow: 1;
            > * {
                &:first-child {
                    @media(min-width: $screen-sm) {
                        max-height: $dashboard-widget-height;
                    }
                }
                flex-grow: 1;
                overflow: auto;
                &.card-list__view-all {
                    flex-grow: 0;
                }
            }
        }
        .card-tabs + .card-list > *:first-child {
            max-height: $dashboard-widget-height - $card-tabs-height;
        }
    }
    balance-summary-widget {
        display: block;
        margin-bottom: 2rem;
    }
}

// Dashboard links

.dashboard-links {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 2rem 1rem;
    width: 100%;
    font-size: 1.5rem;
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
            opacity: $hover-opacity;
        }
        > * {
            display: flex;
            align-items: center;
            &:first-child {
                margin-bottom: 1rem;
                > * {
                    border-radius: 50%;
                    box-shadow: $box-shadow;
                    height: 5rem;
                    width: 5rem;
                    object-fit: cover;
                    font-size: 2.75rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}

// Twitter
twitter-feed {
    .card-style {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > * {
            &:first-child {
                overflow: auto;
            }
            &:last-child {
                padding-top: 1.5rem;
                border-top: solid 1px $gray-lighter;
            }
        }
    }
}
// Target .ngtweet-wrapper everywhere
.ngtweet-wrapper {
    padding-right: 1rem;
}