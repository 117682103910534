.sport-icon {
    > i, > div {
        font-size: 1.8rem;
        font-weight: $semi-bold;
        opacity: 0.75;
        padding: 1rem;
        border-radius: 1rem;
        box-shadow: $box-shadow;
        width: 3.5rem;
        height: 3.5rem;;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

sb-icon {
    position: relative;
    .icon-overlay {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.icon-stretch i {
    // Widen for consistent width across various icons
    width: 25px;
}