﻿.ghosted-div-load-container {
    position: relative;
    clear: both;
}
.ghosted-div-load-container .ghosted-div-loader {
    width: 100%;
    position: absolute;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
}
