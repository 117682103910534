.stamp {
    transform: rotate(12deg);
      color: #555;
      font-size: 3rem;
      font-weight: 700;
      border: 0.25rem solid #555;
      display: inline-block;
      padding: 0.25rem 1rem;
      text-transform: uppercase;
      border-radius: 1rem;
      font-family: 'Courier';
     mix-blend-mode: multiply;
     position: absolute;
     top: 50%;
     left: 50%;
  }

  .is-nope {
    color: #D23;
    border: 0.5rem double #D23;
    transform: rotate(3deg);
    font-size: 2rem;  
  }
  