@import '../../../../../Content/ThirdPartyOverrides/bootstrap/utilities/spacing';
@import '../../../../../Content/ThirdPartyOverrides/bootstrap/sb-spacing';

.gap-1 {
    gap: .25rem;
}

.gap-2 {
    gap: .5rem;
}

.gap-3 {
    gap: 1rem;
}

.gap-4 {
    gap: 1.5rem;
}

.gap-5 {
    gap: 2rem;
}
