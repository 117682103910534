.balance-summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 1rem;
    padding: 0rem 0.5rem;
    &.balance-summary--long {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
    > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        flex-basis: 0;
        > button, a {
            background-color: $white;
            box-shadow: $box-shadow;
            padding: 0.3rem;
            border-radius: $border-radius-large;
            border-top-right-radius: $border-radius-large;
            border-bottom-right-radius: $border-radius-large;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $white;
            border-radius: 5px;
            height: 100%;
            > * {
                &:first-child {
                    text-align: left;
                    padding: 1rem;
                    z-index: 2;
                }
            }
            h3 {
                font-weight: $semi-bold;
            }
            h2 {
                margin-top: 1rem;
                font-size: 3rem;
                font-weight: $semi-bold;
            }
            > sb-icon {
                width: 8.5rem;
                right: 1.5rem;
                opacity: 0.5;
                font-size: 4.5rem;
                z-index: 1;
            }
            &:hover, &:focus, &:active {
                opacity: $hover-opacity;
            }
        }
    }
}

@media (min-width:$screen-sm){
    .balance-summary {
        flex-direction: row;
    }
}