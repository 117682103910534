﻿.gutter-m-1 { margin-left: $grid-gutter-width; margin-right: $grid-gutter-width; }
.gutter-m-2 { margin-left: $grid-gutter-width*2; margin-right: $grid-gutter-width*2; }
.gutter-m-h { margin-left: $grid-gutter-width*0.5; margin-right: $grid-gutter-width*0.5; }

.gutter-m-l-1 { margin-left: $grid-gutter-width; }
.gutter-m-l-2 { margin-left: $grid-gutter-width*2; }
.gutter-m-l-h { margin-left: $grid-gutter-width*0.5; }

.gutter-m-r-1 { margin-right: $grid-gutter-width; }
.gutter-m-r-2 { margin-right: $grid-gutter-width*2; }
.gutter-m-r-h { margin-right: $grid-gutter-width*0.5; }


.gutter-p-1 { padding-left: $grid-gutter-width; padding-right: $grid-gutter-width; }
.gutter-p-2 { padding-left: $grid-gutter-width*2; padding-right: $grid-gutter-width*2; }
.gutter-p-h { padding-left: $grid-gutter-width*0.5; padding-right: $grid-gutter-width*0.5; }

.gutter-p-l-1 { padding-left: $grid-gutter-width; }
.gutter-p-l-2 { padding-left: $grid-gutter-width*2; }
.gutter-p-l-h { padding-left: $grid-gutter-width*0.5; }

.gutter-p-r-1 { padding-right: $grid-gutter-width; }
.gutter-p-r-2 { padding-right: $grid-gutter-width*2; }
.gutter-p-r-h { padding-right: $grid-gutter-width*0.5; }

