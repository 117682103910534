﻿.title-bar__container {
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0px;
    z-index: 500;
    background: $off-white;
    .alert-success {
        margin-bottom: 0;
        .form-group {
            padding: 0.5em;
            label {
                padding-right: 0.5em;
            }
        }
    }
    .collapse-settings-btn {
        font-size: 1.15em;
        background: transparent;
        &:hover {
            filter: brightness(0.8);
        }
    }
}

.title-bar {
    clear: both;
    position: relative;
    padding: $line-height-computed*0.5 $grid-gutter-width*0.5;
    @media (max-width: $screen-xs-max) {
        padding: 8px;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: solid 1px $gray-light;
    width: calc(100% - 2rem);
    min-height: 56px;
    margin: 1rem auto;
    .title-bar__title-area {
        display: flex;
        align-items: center;
    }
    h3 {
        font-weight: $semi-bold;
        display: flex;
        align-items: center;
    }
    .btn {
        margin-right: 0.5rem;
        &:last-child {
            margin-right: 0;
        }
    }
    > .form-flex {
        > .form-group {
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
        select {
            max-width: 100%;
        }
    }
}

.title-bar-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-bar__left {
    display: inline-flex;
    align-items: center;
    > div {
        display: flex;
        align-items: center;
        margin-left: 1em;
        button.hidden-xs {
            margin-left: 4em;
        }
    }
}

.title-bar__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: 4rem;
    flex-wrap: wrap;
}

.title-bar__title {
    padding-right: $grid-gutter-width*0.5;
}

.title-bar__utility {
    text-align: right;
    padding-left: $grid-gutter-width*0.5;
    right: $grid-gutter-width*0.5;
    padding: 0.5rem 0rem;
    &.title-bar__utility-form {
        display: block;
    }
    sb-submit-button {
        margin-right: 5px;
        &:last-child {
            margin-right: 0px;
        }
    }
}

.title-bar__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    flex-basis: 600px;
    flex-grow: 1;
    @extend .text-muted;
    margin: 1rem 0 1rem 0;
    .title-bar__body__description {
        margin-right: 1rem;
    }
    .title-bar__body__note {
        font-style: italic;
        margin-top: 1rem;
        @extend .text-info;
    }
}

.title-bar__link {
    flex-basis: 100px;
    flex-grow: 1;
    margin: 1rem 0 1rem 2rem;
    text-align: right;
}

.title-bar-form {
    @media (max-width: $screen-xs-max) {
        display: block;
    }
    .title-bar__utility {
        display: block;
    }
}

@media (max-width: $screen-sm) {
    
    .title-bar__title {
        max-width: 100%;
        padding-right: 0;
    }

    .title-bar__utility {
        position: static;
        max-width: 100%;
        padding-left: 0;
        transform: none;
    }
}

.modal-dialog {
    .title-bar {
        .title-bar__title {
            max-width: 70%;
            padding-right: $grid-gutter-width*0.5;
        }
    }
}

