﻿.setting-type-box {
    border: 1px solid lightgrey;
    padding: 10px;
    display: inline-block;
}

.modules-box {
    @extend .setting-type-box;
}

.domain-name-box {
    @extend .setting-type-box;
}

.email-domains-box {
    @extend .setting-type-box;
}

.calendar-event-types-box {
    @extend .setting-type-box;
}

#divSubDomainPleaseNote ol,
#divManageCcaSignupModulesError ol,
#divAddNewCcaSignupError ol,
#divManageModuleTypesError ol,
.moduleUpsell ol
{
    list-style: decimal;
    margin-left: 2em;
}

.moduleUpsell ul {
    list-style: circle;
    margin-left: 2em;
}

#divSubDomainPleaseNote p,
.moduleUpsell p,
.moduleUpsell ul {
    margin-bottom: 10px;
}

.moduleUpsell h1,
.moduleUpsell h2,
.moduleUpsell h3,
.moduleUpsell h4,
.moduleUpsell h5 {
    margin-bottom: 10px;
}

#divSubDomainPleaseNote ol li div span {
    font-style: italic;
}

/* Chrome, Safari, Edge, Opera */
#minLastSeenDays::-webkit-outer-spin-button,
#minLastSeenDays::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
#minLastSeenDays {
    -moz-appearance: textfield;
}

#tblSchoolIds {
    width: 95%;
}

.min-width-250
{
    min-width: 250px;
}

.width-80{
    width: 80px !important;
    min-width: 80px !important;
}

.width-200
{
    width: 200px;
}

.width-300
{
    width: 300px;
}

.width-400 {
    width: 400px;
}

.width-20pc {
    width: 20%;
}

.width-60pc
{
    width: 60%;
}

.inline-block
{
    display: inline-block;
}

.hidden
{
    display:none;
}

.clear-both
{
    clear: both;
}

.no-top-border
{
    border-top-style: hidden;
}

hr.show-border {
    border: 1px solid #eeeeee !important;
}

.border-top-none
{
    border-top: none !important;
}

.border-bottom-none {
    border-bottom: none !important;
}