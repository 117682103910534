﻿.input-group {
    display: flex;
    align-items: stretch;
    width: auto;
}

.input-group-addon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 5rem;
    padding: $padding-base-vertical*0.5 $padding-base-horizontal;
    background: $off-white;
    
    // Sizing
    &.input-sm {
        padding: $padding-small-vertical*0.5 $padding-small-horizontal;
        
    }

    &.input-lg {
        padding: $padding-large-vertical*0.5 $padding-large-horizontal;
        
    }
}

.input-group-btn {
    width: auto;
}

@media (min-width: $screen-xs-min) {
    .form-inline .input-group {
        display: inline-flex;
        margin: 0.5rem;
    }
}