﻿.planner-radiobutton {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    height: 20px;
    width: 20px;
    border: 1px solid $gray-light;
    cursor: pointer;
    transition: border-color 0.25s ease;
    background-color: $white;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;


    &:hover {
        border-color: $gray;
    }

    &:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        height: 14px;
        width: 14px;
        transition: background-color 0.25s ease;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        border-radius: 7px;
        // background-color: $gray-light;
    }

    &.disabled {
        background-color: $gray-light;
        cursor: not-allowed;
    }
}

.planner-radiobutton--lg {
    height: 30px;
    width: 30px;
    border-radius: 15px;

    &:after {
        height: 24px;
        width: 24px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 12px;
    }
}

.planner-radiobutton--success {
    &:after {
        background-color: $brand-success;
    }

    &.disabled:after {
        background-color: $gray;
    }
}

.planner-radiobutton--warning {
    &:after {
        background-color: $brand-warning;
    }

    &.disabled:after {
        background-color: $gray;
    }
}

.planner-radiobutton--danger {
    &:after {
        background-color: $brand-danger;
    }

    &.disabled:after {
        background-color: $gray;
    }
}