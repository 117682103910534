﻿.message-center {
    height: calc(100vh - 162px);
    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select:focus,
    textarea {
        @media (max-width: $screen-xs-max) {
            /* this will stop the iphone zooming on click of a text box */
            font-size: 16px;
        }
    }
    /* stop ie adding it's own clear button to text boxes */
    input[type=text]::-ms-clear {
        display: none;
    }
    /*
    > div:nth-child(2) {
        @media (min-width: $screen-xs-max) {
            border-right: 1px solid #ccc;
            border-left: 1px solid #ccc;
        }
    }
    */
    div.start-of-body {
        border-top: 1px solid #ccc;
        margin-top: 1em;
    }

    > div {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow: auto;
        padding-bottom: 15px;
        height: 100%;

        @media screen and (max-width: $screen-sm-max) {
            height: auto;
        }
    }

    .nav-tree {
        display: flex;
        flex-direction: column;
        li {
            display: flex;
            flex-direction: column;
            margin-bottom: 0.5rem;
            overflow: hidden;
            @media (min-width: $screen-sm-min) {
                .selected {
                    font-weight: bold;
                    border-radius: 0;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                    background-color: $brand-primary;
                    background-image: $background-lighten-9;
                    color: $brand-primary;
                }
            }
            button {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 35px;
                padding-left: 3rem;
                margin-bottom: 0.5rem;
                text-align: left;
                font-size: 1.4rem;
                color: $gray-dark;
                justify-content: space-between;
                div {
                    &:nth-child(2) {
                        font-size: 1.2rem;
                        font-weight: $semi-bold;
                    }
                }
                &:hover {
                    opacity: $hover-opacity;
                }
            }
        }
    }

    .data-source-title {
        line-height: 32px;
    }

    .message-list {

        li {
            border-bottom: 1px solid $gray-light;
            padding: 5px;
            font-size: 1.4rem;
            cursor: pointer;

            &.selected {
                background-color: $brand-primary;
                background-image: $background-lighten-9;
                color: $brand-primary;
            }

            &.unreadlight {
                font-weight: bold;

                small, em {
                    font-weight: normal;
                }
            }

            &.unread {
                font-weight: bold;

                small, em {
                    font-weight: normal;
                }
            }

            &.unread:before {
                content: '';
                margin-top: 7px;
                margin-left: -13px;
                line-height: 0px;
                position: absolute;
                border-radius: 4px;
                height: 7px;
                width: 7px;
                background-color: $brand-danger;
            }

            &:hover {
                opacity: $hover-opacity;
            }

            em {
                display: block;
                font-size: 1.2rem;
                font-weight: normal;
                text-align: right;
            }

            i {
                margin-left: 0.5em;
            }
        }
    }

    .big-icon-panel .glyphicon {
        font-size: 1.5em;
    }

    .filter-panel-button-active {
        background-color: #fcf8e3;
    }

    .filter-panel {
        padding: 1em;
        border-bottom: solid 1px #ccc;
        margin-bottom: 1em;

        .form-group {
            clear: both;
        }
    }

    .form-group {
        margin-bottom: 10px;
    }

    h1, h2, h3 {
        margin-top: 0.8em;
        margin-bottom: 0.4em;
    }
}

.message-counts-component {
    > div:first-child >  div:first-child{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-bottom: solid 1px $gray-lighter;
        > .btn {
            display: flex;
            align-items: center;
            text-align: left;
            color: $gray-dark;
            flex-basis: 50%;
            justify-content: center;
            padding-bottom: 2rem;
            > .widget-icon {
                margin-right: 1.25rem;
                border: solid 1px;
                border-color: $gray-lighter;
                background-color: $off-white;
                color: $brand-primary;
            }
            &:first-child {
                border-right: solid 1px $gray-lighter;
            }
        }
    }
}

.tag-list {
    div {
        background-color: #eee;
        border: solid 1px #ccc;
        border-radius: 3px;
        display: inline-block;
        padding: 3px 7px;
        margin-right: 3px;
        font-size: 0.8em;
    }
}
