﻿// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)


.pad-icon-right {
  .fa {
    &:before {
      padding-right: $padding-xs-horizontal;
    }
  }
}
.list-striped {
  > li:nth-of-type(odd) {
    background-color: $table-bg-accent;
  }

  > div:nth-of-type(odd) {
    > .row {
      > .col {
        background-color: $table-bg-accent;
      }
    }
  } 
}

.list-hover {
    > li {
        transition: background-color 0.25s ease;
        cursor: pointer;

        &:hover {
            background-color: $table-bg-hover;
        }
    }
}