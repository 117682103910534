﻿@media (min-width: $screen-xs-max) {
    .dynamic-height {
        overflow: hidden;
        overflow-y: auto;

        &.hidden-scroll {
            overflow-y: hidden;

            &:hover {
                overflow-y: auto;
                padding-right: 0;
            }
        }
    }
}
@media (max-width: $screen-xs-max) {
    .dynamic-height {
        height:auto !important;
    }
}

.loading {
    text-align: center;
    vertical-align: middle;
    line-height: 4em;
}