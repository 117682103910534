﻿.h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
}

.page-header {
    margin-top: $line-height-computed;
    margin-bottom: $line-height-computed*0.5;
}

.text-xs-left { text-align: left; }
.text-xs-right { text-align: right; }
.tzext-xs-center { text-align: center; }
.text-xs-justify { text-align: justify; }

 @media (min-width: $screen-sm-min) {
    .text-sm-left { text-align: left; }
    .text-sm-right { text-align: right; }
    .text-sm-center { text-align: center; }
    .text-sm-justify { text-align: justify; }
 }

 @media (min-width: $screen-md-min) {
    .text-md-left { text-align: left; }
    .text-md-right { text-align: right; }
    .text-md-center { text-align: center; }
    .text-md-justify { text-align: justify; }
}

@media (min-width: $screen-lg-min) {
    .text-lg-left { text-align: left; }
    .text-lg-right { text-align: right; }
    .text-lg-center { text-align: center; }
    .text-lg-justify { text-align: justify; }
}

blockquote {
    font-size: inherit;
    border-left: none;
}

@media (max-width: 767px) {
    .pull-left-xs {
        float: left !important;
    }
    .pull-right-xs {
        float: right !important;
    }
}