// Searching, filtering and viewing data

.search-settings-area {
    background-color: rgba(240,240,240,0.9);
    position: sticky;
    top: 0px;
    z-index: 500;
    .form-group {
        padding: 0.5em 1em;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        label {
            margin-right: 0.75em;
        }
    }

}

.collapse-settings-btn {
    color: $gray-darker;
    font-size: 1.1em;
    background: transparent;
    border: 0;
    box-shadow: none;
    &hover {
        filter: brightness(0.8);
    }
    i {
        font-size: 1.1em;
        margin-right: 0.3em;
    }
}

.filter-area {
    padding: 1.5rem;
    border-bottom: solid 1px $gray-light;
    margin-bottom: 1.5rem;
    .filter-area-inner {
        display: flex;
        flex-direction: column;
        @media(min-width: $screen-sm-min) {
            padding: 1.25rem;
        }
        .filter-area-inner__top {
            @extend .space-between;
            flex-wrap: wrap;
            > * {    
                padding: 1.25rem 1rem;
            }
            .filter-area-inner__main {
                display: flex;
                flex-direction: column;
                @media (min-width: $screen-sm-min) {
                    flex-direction: row;
                }
                flex-grow: 1;
                > div {
                    &.control-label {
                        width: 120px;
                    }
                    &.filter-area-inner__controls {
                        display: flex;
                        justify-content: space-between;
                        flex-grow: 1;
                        @media (min-width: $screen-sm-min) {
                            justify-content: flex-start;
                        }
                        .filter-area-inner__input {
                            flex-basis: 425px;
                            @media (min-width: $screen-sm-min) {
                                padding: 0rem 2rem;
                            }
                        }
                        .filter-area-inner__input {
                            flex-basis: 425px;
                            @media (min-width: $screen-sm-min) {
                                padding: 0rem 2rem;
                            }
                        }
                    }
                }
            }
            .filter-area-inner__page-size {
                display: flex;
                align-items: center;
                color: $text-muted;
                > div {
                    &:first-child {
                        margin-right: 1rem;
                    }
                    select {
                        background: transparent;
                        box-shadow: none;
                        border: 0;
                        font-size: 1.3rem;
                        color: $text-muted;
                    }
                }
            }
            .filter-area-inner__page-size {
                display: flex;
                align-items: center;
                color: $text-muted;
                > div {
                    &:first-child {
                        margin-right: 1rem;
                    }
                    select {
                        background: transparent;
                        box-shadow: none;
                        border: 0;
                        font-size: 1.3rem;
                        color: $text-muted;
                    }
                }
            }
        }
        .collapse-inner {
            padding-top: 3rem;
            max-width: $filter-content-width;
            display: flex;
            position: relative;
            justify-content: space-between;
            > button.close {
                position: absolute;
                top: 4rem;
                right: 1.5rem;
                font-size: 2rem;
            }
            > div {
                padding-top: 3rem;
                border-top: solid 1px $gray-light;
                flex-wrap: wrap;
                display: flex;
                sb-search-filter-item {
                    flex-basis: 500px;
                    &.sb-search-filter-item-100 {
                        flex-basis: 100%;
                        > div {
                            &:last-child {
                                @media (min-width: $screen-sm-min) {
                                    flex-basis: calc(100% - 170px);
                                }
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                .checkbox-container {
                                    margin: 0.5rem 3rem 0.5rem 0rem;
                                    margin-right: 3rem;
                                    margin-bottom: 1rem;
                                    &:last-child {
                                        padding-bottom: 1rem;
                                    }
                                    .control-label {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                    padding: 0 3rem 3rem 0;
                    display: flex;

                    > div {
                        flex-grow: 1;
                        &:first-child {
                            @media (min-width: $screen-sm-min) {
                                flex-basis: 120px;
                            }
                            padding-right: 1rem;
                        }
                        &:last-child {
                            @media (min-width: $screen-sm-min) {
                                flex-basis: 300px;
                            }
                            input, select {
                                max-width: 325px;
                                &[date-range-picker] {
                                    max-width: none;
                                }
                            }
                        }
                    }
                    label:first-of-type .planner-checkbox {
                        margin-left: 1em;
                    }
                }
                &:first-child {
                    flex-grow: 1;
                }
                &:last-child {
                    flex-direction: column;
                    justify-content: flex-end;
                }
            }
        }
        .control-label{
            font-weight: 600;
            padding-top: 0.5rem;
            padding-bottom: 1rem;
        }
        .dropdown-multiselect {
            padding: 0;
            width: 100%;
            > button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 10px;
                box-shadow: none;
            }
        }
    }
    &.diary-picker {
        // background-color: $brand-primary;
        // White on all themes
        // color: white;
        position: sticky;
        top: 0;
        z-index: 100;
        margin-bottom: 0;
        padding: 0;
        .filter-area-inner {
            max-width: none;
            .filter-area-inner__top {
                flex-wrap: nowrap;
            }
            .collapse-inner {
                padding-left: 1rem;
                padding-right: 1rem;

                > div {
                    sb-search-filter-item {
                        .control-label {
                            color: white;
                        }
                    }
                }
            }
        }
        sb-date-range-picker {
            input.form-control, div {
                color: $white;
                border: none;
                background: none;
                box-shadow: none;
                font-weight: $semi-bold;
            }
        }
        .collapse-settings-btn, .btn-link {
            color: $white;
        }
        .btn-light {
            background: rgba(255,255,255,0.5);
        }
        .filter-date-picker {
            display: flex;
            justify-content: stretch;
            align-items: center;
            color: $gray-light;
            margin-top: 1rem;
            .filter-date-picker__inner {
                display: flex;
                overflow: auto;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
                > div {
                    display: flex;
                    > div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0.75rem;
                        border-radius: $border-radius-large;
                        position: relative;
                        margin-right: 0.5rem;
                        cursor: pointer;
                        > div {
                            margin-bottom: 0.2rem;
                            &:first-child {
                                text-transform: uppercase;
                            }
                            &:nth-child(2) {
                                color: $white;
                                font-size: 1.3rem;
                                padding: 0.3rem;
                                margin: 0.2rem;
                                width: 2.5rem;
                                height: 2.5rem;
                                display: flex;
                                justify-content: center;
                            }
                            &:last-child {
                                margin-bottom: 0;
                                display: flex;
                                justify-content: center;
                                > div {
                                    height: 0.5rem;
                                    width: 0.5rem;
                                    margin: 0.2rem;
                                    border-radius: 50%;
                                    background-color: $brand-secondary;
                                }
                            }
                        }
                    }
                    &:last-child {
                        > div {
                            margin-right: 0;
                        }
                    }
                    &.filter-date-picker__current-date > div {
                        background-color: $gray-translucent;
                        cursor: default;
                        // color: $white;
                        > div {
                            &:nth-child(2) {
                                // background-color: $white;
                                color: $brand-primary;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-weight: $semi-bold; 
                            }
                        }
                    }
                    &.filter-date-picker__end-of-week {
                        &::after {
                            content: ' ';
                            width: 1px;
                            background-color: $gray-light;
                            margin: 1rem;
                        }
                    }
                    &.filter-date-picker__empty-date > div {
                        cursor: default;
                        opacity: 0.5;
                    }
                }
            }
            .date-picker-arrow {
                flex-basis: 70px;
                height: 100%;
                // color: $white;
                font-size: 2rem;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                display: none;
                &.disabled {
                    color: $gray;
                    opacity: 0.25;
                    cursor: default;
                }
            }
            // Show left/right arrows on non-touch devices
            @media (hover: hover) {
                .date-picker-arrow {
                    display: flex;
                    &:hover:not(.disabled) {
                        opacity: 0.75;
                    }
                }
            }
        }
    }
}

// Independent of parent as also used on messages page

.filter-area__search-box{
    display: flex;
    input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    button {
        border: solid 1px $gray-light;
        border-radius: 0 2px 2px 0;
        // height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.checkbox-container {
    padding: 0 0 1rem 0;
    display: flex;
    align-items: center;
    &:last-child {
        padding-bottom: 0;
    }
    .planner-checkbox {
        margin-right: 0.5rem;
    }
}

// Content and tables

.table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    button {
        margin-top: 1em;
    }
    div {
        padding: 0.75em;
    }
}

.pagination-export {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column-reverse;
    @media (min-width: $screen-sm-min) {
        flex-direction: row;
        align-items: center;
    }
}

.search-filter-item-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;
}

.filter-area .filter-area-inner .collapse-inner > div .search-filter-item-container sb-search-filter-item {
    flex-basis: 350px;
    flex-grow: 1;
    align-items: center;

    .control-label {
        text-align: right;
        padding-top: 0;
    }

    .checkbox-container {
        margin: 0 !important;
        padding: 0 !important;
    }
}
