﻿.fake-select {
    position:relative;
    width: inherit;
    max-width: 100%;

    .open {
        position: absolute;
        background-color: $white;
        z-index: 999;
        width: 100%;
        border: 1px solid #ccc;

        .form-control-static {
            padding-left: 10px;
            &:hover {
                background-color: $brand-info;
            }
        }
    }
    > div {
        &:first-child {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &.fake-select__open {
            display: flex;
            flex-direction: column;
            position: fixed;
            border-radius: 5px;
            border-top-left-radius: 0;
            background-color: $white;
            overflow-x: hidden;
            width: inherit;
            > * {
                flex-grow: 1;
                &.fake-select__search {
                    padding: 0.5rem;
                }
                &.fake-select__list {        
                    text-align: left;
                    max-height: 250px;
                    width: 300px;
                    max-width: 95vw;
                    overflow-x: hidden;
                    overflow-y: auto;
                    margin-top: 2px;
                    .fake-select__option {
                        margin-bottom: 0.5rem;
                        &:hover, &.fake-select__highlighted {
                            background-color: $brand-info;
                        }
                    }
                    &.fake-select__list-grid {
                        display: grid;
                        grid-gap: 1rem;
                        grid-template-columns: 1fr 1fr 1fr;
                        .fake-select__option {
                            flex-direction: column;
                            justify-content: center;
                            padding: 0.5rem;
                            max-height: 5rem;
                            > * {
                                &:last-child {  
                                    font-size: 1rem;
                                }
                            }
                            img, i {
                                margin-bottom: 0.5rem;
                                margin-right: 0;
                            }
                            i {
                                font-size: 2rem;
                            }
                            img {
                                height: 2.2rem;
                                width: 2.2rem;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }
        }
    }
    img {
        height: 20px;
        width: 30px;
        min-width: 20px;
        object-fit: contain;
    }
    img, i {
        margin-right: 1rem;
    }
    .fake-select__option {
        display: flex;
        align-items: center;
        min-width: 0;
        padding-left: 10px;
        max-height: 1rem;
        &:last-child {
            margin-bottom: 0;
        }
        > * {
            &:last-child {            
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 100%;
            }
        }
        img, sb-icon {
            flex-shrink: 0;
        }
    }
    .fake-select__invalid {
        border: solid 1px $brand-danger;
    }
    &.fake-select-colour-icons {        
        i {
            color: var(--brand-primary);
            &.fad {
                &::after {
                    color: var(--brand-secondary);
                    opacity: 1;
                }
            }
        }
    }
}

.input-group-addon {
    .fake-select {
        > div {
            &:first-child {
                padding: 0;
                background: 0;
                border: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-shadow: none;
                sb-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 5px;
                }
            }
            &:last-child {
                width: auto;
                box-shadow: none;
                margin-top: 28px;
            }
        }
    }
}
