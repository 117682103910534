﻿@use "sass:math";

.form-group > label {
    margin-bottom: 2px;
}

.form-condensed {
    .form-group {
        margin-bottom: $line-height-computed*0.5;
    }
}

.account {
    .ng-invalid {
        .control-label {
            color: $gray-darker;
        }
        .form-control {
            border-color: $input-border;
        }
    }
}

input[type=checkbox].mobile {
    width: 3rem;
    height: 3rem;
}

.checkbox-lg {
  height: 1.5rem;
  width: 1.5rem;
}

@media (min-width: $screen-lg-min) {
    .form-horizontal {
      .control-label.col-lg-text-left {
        text-align: left;
      }
      > fieldset > .form-group > div.col-sm-9:last-child {
        padding: 0.75rem;
      }
    }
}

.form-control {
    font-size: $font-size-base + 2;
}

.form-control-sm {
    font-size: $font-size-base;
}

input[type=checkbox]:required:invalid {
  height: auto;
  margin: 12.5px 0px 13px;
  outline: 1px solid #fc5255;
  outline-offset: -1px;
}

.form-subheading {
    margin-bottom: 2rem;
    color: $gray-dark;
    font-weight: $semi-bold;
    font-size: 1.4rem;
}

.form-section {
    margin-bottom: 20px;
    padding-bottom: 25px;
    border-bottom: solid 1px $gray-lighter;
    &:last-child{
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 10px;
    }
}

.form-summary {
    font-size: 1.7rem;
    padding-left: 5px;
    color: $gray-dark;
}

.form-summary-updated-item {
    color: $brand-primary;
    &::before {
        font-family: 'Font Awesome 5 Pro';
        content: '\f067';
    }
}

select {
    min-width: 5rem;
}

.select-lg {
    min-height: 2.4em;
    min-width:10em;
}

//Flex forms

$flex-form-item-width: 18rem;

.form-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > div {
    display: flex;
  }
 > .form-group, > .form-item {
    padding: 1rem;
    margin: 0;
    align-items: center;
    flex-basis: $flex-form-item-width;
    flex-grow: 1;
    justify-content: space-between;
    label {
      margin-right: 1em;
      margin-bottom: 0;
    }
    .input-group label {
      margin-right: 0;
    }
    sb-time-picker {
      > div {
        display: flex;
        > span {
          &:first-child {
            margin-right: 1rem;
          }
        }
      }
    }
    @media (max-width: $screen-xs-max) {
      flex-basis: 100%;
    }
  }
  select {
    max-width: $flex-form-item-width;
    &.select-wide {
      max-width: 35rem;
    }
  }
  @media (max-width: $screen-xs-max) {
    &.xs-single-column {
      > * {
        flex-basis: 100%;
      }
    }
}
}

@media (min-width: ($flex-form-item-width * 2)) {
  .form-flex {
    > .form-group, .form-item {
      flex-grow: 0;
    }
  }
}

@media (min-width: $screen-sm-min){
  .form-flex {
    > .form-group, .form-item {
      flex-basis: auto;
    }
  }
}

.borderless {
  border: 0!important;
  box-shadow: none!important;
}

$reduced-negative-margin: -7px;

.card-style .form-horizontal .form-group {
  //Reduce negative margin from 15px to -7px
  margin-left: $reduced-negative-margin;
  margin-right: $reduced-negative-margin;
}


@media (max-width: $screen-xs-max) {
  .form-horizontal .form-group {
    // Reduce negative margin from -15px to 7px
    margin-left: $reduced-negative-margin;
    margin-right: $reduced-negative-margin;
  }
}

// Wizard

.wizard {
  &.modal-body {
    padding-right: 10px;
  }
  .wizard__tabs {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    font-size: 1.5rem;
    padding: 0.5rem 2rem 1rem 2rem;
    > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      flex-grow: 1;
      position: relative;
      .tab-line {
        content: ' ';
        position: absolute;
        width: 100%;
        top: 2.25rem;
        border-bottom: solid 1px $brand-secondary;
        @media (max-width: $screen-xs-max) {
          top: 1.75rem;
        }
      }
      &:first-child .tab-line {
        left: 50%;
        width: 50%;
      }
      &:last-child .tab-line {
        width: 50%;
        left: 0%;
      }
      > * {
        &:first-child {
          font-size: 2rem;
          height: 4.5rem;
          width: 4.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1rem;
          border-radius: 50%;
          margin-bottom: 1rem;
          border: solid 1px;
          color: $brand-secondary;
          background-color: $white;
          z-index: 1;
          @media (max-width: $screen-xs-max) {
            font-size: 1.5rem;
            height: 3.5rem;
            width: 3.5rem;
          }
          &::after {
            height: 1px;
            width: 100px;
          }
        }
      }
      &.active {
        color: $brand-secondary;
        cursor: default;
        > * {
          &:first-child {
            background-color: $brand-secondary;
            color: #ffffff;
            border: 0;
          }
        }
      }
    }
  }
  .wizard__tab-heading {
    margin-bottom: 25px;
    text-align: center;
    color: var(--brand-secondary);
  }
  .wizard__main {
    padding-right: 1rem;
    &::-webkit-scrollbar {
      width: 5px;
    }
    @media(min-width: $screen-sm) {
      display: grid;
      grid-template-columns: 1fr 700px;
      &.wizard__main-no-image {
        grid-template-columns: 1fr;
      }
    }
    > .wizard__image {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      > * {
        width: 100%;
        display: flex;
        justify-content: center;
        svg {
          width: 70%;
          max-width: 450px;
          max-height: 45vh;
        }
      }
    }
  }
}

.form-columns {
  @media(min-width: $screen-sm) {
    padding: 1rem 2.5rem;
    overflow: auto;
  }
  fieldset {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .form-group {
    margin: 0rem 0rem 2rem 0;
    flex-basis: 100%;
    min-height: 62px;
    .control-label {
      padding: 0.5rem 0.75rem;
      font-size: 1.4rem;
      color: $gray-darker;
  }
    &.form-group-decorative {
      min-height: auto;
    }
    @media(min-width: $screen-sm) {
      @for $i from 1 through 9 {
        &.form-group-#{$i} {
          flex-basis: calc(#{percentage($i * math.div(10, 100))} - 1rem);
          // Max width so form area doesnt's change when select options made
          max-width: calc(#{percentage($i * math.div(10, 100))} - 1rem);
        }
      }
      h2 {
        font-weight: $semi-bold;
      }
    }
    &.form-group-expanded {
      width: 100%;
      height: 100%;
      > div > div:first-child {
        font-size: 1.5rem;
        padding-bottom: 1.5rem;
      }
    }
  }
  &.form-columns-compact {
    .form-group {
      margin-bottom: 1.5rem;
    }
    .control-label {
      font-size: 1.3rem;
    }
  }
}

.wizard__form {
  @extend .form-columns;
}


$modal-form-max-height: calc(100vh - 300px);
$modal-form-expanded-max-height: calc(100vh - 150px);
  @media(min-width: $screen-sm) {
  .modal-dialog {
    .wizard__form {
      max-height: $modal-form-max-height;
    }
    .wizard__main-expanded {  
      .wizard__form {
        max-height: $modal-form-expanded-max-height;
      }
    }
    &.modal-xl {
      .wizard__form {
        height: $modal-form-max-height;    
      }
      .wizard__main-expanded {  
        .wizard__form {
          height: $modal-form-expanded-max-height;
          max-height: $modal-form-expanded-max-height;
        }
      }
    }
  }
}

.icon-overlay.tab-done {
  bottom: -5px;
  right: -5px;
  background: $white;
  border-radius: 50%;
  color: $brand-success;
  border: solid 1px $white;
}

.form-buttons {
  margin-top: 5rem;
}
