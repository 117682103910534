﻿/* styles for validation helpers */
.input-validation-error {
    border: 1px solid $brand-danger !important;
}

.panel-heading .accordion-toggle:after {
    /* symbol for "opening" panels */
    //font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    //content: "\e114";    /* adjust as needed, taken from bootstrap.css */
    //float: right;        /* adjust as needed */
    //color: grey;         /* adjust as needed */
}
.panel-heading .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    // content: "\e080";    /* adjust as needed, taken from bootstrap.css */
}

#loader 
{
    text-align: center;
}


/*
	Sports Icons
	------------
*/
.sports {
    // background-image: url(../Images/sports.png);
    background-repeat: no-repeat;
    display: block;
    width: 40px;
    height: 40px;
}

.sporticonGAA_football {background-position: 0 0;}
.sporticonarchery {background-position: -40px 0;}
.sporticonathletics {background-position: -80px 0;}
.sporticonbadminton {background-position: -120px 0;}
.sporticonbaseball {background-position: -160px 0;}
.sporticonbasketball {background-position: -200px 0;}
.sporticonbeach_volleyball {background-position: -240px 0;}
.sporticonboxing {background-position: -280px 0;}
.sporticoncanoeing2 {background-position: -320px 0;}
.sporticoncanoeing {background-position: -360px 0;}
.sporticoncricket {background-position: -400px 0;}
.sporticoncycling_bmx {background-position: -440px 0;}
.sporticoncycling_mountain {background-position: -480px 0;}
.sporticoncycling_road {background-position: -520px 0;}
.sporticoncycling_track {background-position: -560px 0;}
.sporticondarts {background-position: -600px 0;}
.sporticondiving {background-position: -640px 0;}
.sporticonequestrian {background-position: -680px 0;}
.sporticonequestrian_dressage {background-position: -720px 0;}
.sporticonequestrian_eventing {background-position: -760px 0;}
.sporticonequestrian_jumping {background-position: -800px 0;}
.sporticonfencing {background-position: -840px 0;}
.sporticonfootball {background-position: -880px 0;}
.sporticonformula1 {background-position: -920px 0;}
.sporticongolf {background-position: -960px 0;}
.sporticongreyhounds {background-position: -1000px 0;}
.sporticongymnastics {background-position: -1040px 0;}
.sporticongymnastics_rythum {background-position: -1080px 0;}
.sporticongymnastics_trampoline {background-position: -1120px 0;}
.sporticonhandball {background-position: -1160px 0;}
.sporticonhockey {background-position: -1200px 0;}
.sporticonhurling {background-position: -1240px 0;}
.sporticonice_hockey {background-position: -1280px 0;}
.sporticonjudo {background-position: -1320px 0;}
.sporticonlacrosse {background-position: -1360px 0;}
.sporticonmartial_arts {background-position: -1400px 0;}
.sporticonmodern_pentathlon {background-position: -1440px 0;}
.sporticonmotor_racing {background-position: -1480px 0;}
.sporticonmotorbikes {background-position: -1520px 0;}
.sporticonnetball {background-position: -1560px 0;}
.sporticonpoker {background-position: -1600px 0;}
.sporticonpool {background-position: -1640px 0;}
.sporticonrowing {background-position: -1680px 0;}
.sporticonrugby {background-position: -1720px 0;}
.sporticonsailing {background-position: -1760px 0;}
.sporticonshooting {background-position: -1800px 0;}
.sporticonskateboarding {background-position: -1840px 0;}
.sporticonskiing {background-position: -1880px 0;}
.sporticonsnooker {background-position: -1920px 0;}
.sporticonspeedway {background-position: -1960px 0;}
.sporticonsquash {background-position: -2000px 0;}
.sporticonsurfing {background-position: -2040px 0;}
.sporticonswimming {background-position: -2080px 0;}
.sporticonsync_swimming {background-position: -2120px 0;}
.sporticontable_tennis {background-position: -2160px 0;}
.sporticontennis {background-position: -2200px 0;}
.sporticontenpin_bowling {background-position: -2240px 0;}
.sporticontriathlon {background-position: -2280px 0;}
.sporticontv {background-position: -2320px 0;}
.sporticonvolleyball {background-position: -2360px 0;}
.sporticonwater_polo {background-position: -2400px 0;}
.sporticonweightlifting {background-position: -2440px 0;}
.sporticonwinter_sports {background-position: -2480px 0;}
.sporticonwrestling {background-position: -2520px 0;}

/*
    Sports Icon (Retina)
    --------------------
*/
.sports_2x {
    // background-image: url(../Images/sports@2x.png);
    background-repeat: no-repeat;
    display: block;
    width: 80px;
    height: 80px;
}

.sporticonGAA_football_2x { background-position: -5px -5px; }
.sporticonarchery_2x { background-position: -95px -5px; }
.sporticonathletics_2x {background-position: -185px -5px;}
.sporticonbadminton_2x {background-position: -275px -5px;}
.sporticonbaseball_2x { background-position: -365px -5px;}
.sporticonbasketball_2x { background-position: -455px -5px;}
.sporticonbeach_volleyball_2x { background-position: -545px -5px;}
.sporticonboxing_2x { background-position: -635px -5px;}

.sporticoncanoeing2_2x { background-position: -5px -95px;}
.sporticoncanoeing_2x {background-position: -95px -95px;}
.sporticoncricket_2x {background-position: -185px -95px;}
.sporticoncycling_bmx_2x {background-position: -275px -95px;}
.sporticoncycling_mountain_2x {background-position: -365px -95px;}
.sporticoncycling_road_2x {background-position: -455px -95px;}
.sporticoncycling_track_2x {background-position: -545px -95px;}
.sporticondarts_2x {background-position: -635px -95px;}

.sporticondiving_2x {background-position: -5px -185px;}
.sporticonequestrian_2x { background-position: -95px -185px;}
.sporticonequestrian_dressage_2x { background-position: -185px -185px;}
.sporticonequestrian_eventing_2x { background-position: -275px -185px;}
.sporticonequestrian_jumping_2x { background-position: -365px -185px;}
.sporticonfencing_2x { background-position: -455px -185px;}
.sporticonfootball_2x { background-position: -545px -185px;}
.sporticonformula1_2x { background-position: -635px -185px;}

.sporticongolf_2x {background-position: -5px -275px;}
.sporticongreyhounds_2x {background-position: -95px -275px;}
.sporticongymnastics_2x {background-position: -185px -275px;}
.sporticongymnastics_rythum_2x {background-position: -275px -275px;}
.sporticongymnastics_trampoline_2x {background-position: -365px -275px;}
.sporticonhandball_2x {background-position: -455px -275px;}
.sporticonhockey_2x {background-position: -545px -275px;}
.sporticonhurling_2x {background-position: -635px -275px;}

.sporticonice_hockey_2x {background-position: -5px -365px;}
.sporticonjudo_2x {background-position: -95px -365px;}
.sporticonlacrosse_2x {background-position: -185px -365px;}
.sporticonmartial_arts_2x {background-position: -275px -365px;}
.sporticonmodern_pentathlon_2x {background-position: -365px -365px;}
.sporticonmotor_racing_2x {background-position: -455px -365px;}
.sporticonmotorbikes_2x {background-position: -545px -365px;}
.sporticonnetball_2x {background-position: -635px -365px;}

.sporticonpoker_2x {background-position: -5px -455px;}
.sporticonpool_2x {background-position: -95px -455px;}
.sporticonrowing_2x {background-position: -185px -455px;}
.sporticonrugby_2x {background-position: -275px -455px;}
.sporticonsailing_2x {background-position: -365px -455px;}
.sporticonshooting_2x {background-position: -455px -455px;}
.sporticonskateboarding_2x {background-position: -545px -455px;}
.sporticonskiing_2x {background-position: -635px -455px;}

.sporticonsnooker_2x {background-position: -5px -545px;}
.sporticonspeedway_2x {background-position: -95px -545px;}
.sporticonsquash_2x {background-position: -185px -545px;}
.sporticonsurfing_2x {background-position: -275px -545px;}
.sporticonswimming_2x {background-position: -365px -545px;}
.sporticonsync_swimming_2x {background-position: -455px -545px;}
.sporticontable_tennis_2x {background-position: -545px -545px;}
.sporticontennis_2x {background-position: -635px -545px;}

.sporticontenpin_bowling_2x {background-position: -5px -635px;}
.sporticontriathlon_2x {background-position: -95px -635px;}
.sporticontv_2x {background-position: -185px -635px;}
.sporticonvolleyball_2x {background-position: -275px -635px;}
.sporticonwater_polo_2x {background-position: -365px -635px;}
.sporticonweightlifting_2x {background-position: -455px -635px;}
.sporticonwinter_sports_2x {background-position: -545px -635px;}
.sporticonwrestling_2x {background-position: -635px -635px;}

