﻿.shake {
  animation:shake .3s 2 ;
  display:inline-block;
}


  @keyframes shake {
    33% {
      transform: rotate(30deg);
    }

    66% {
      transform: rotate(-30deg);
    }
  }

  .fade-in {
    animation: sbFadeIn 1.2s;
    -webkit-animation: sbFadeIn 1.2s;
    -moz-animation: sbFadeIn 1.2s;
    -o-animation: sbFadeIn 1.2s;
    -ms-animation: sbFadeIn 1.2s;
  }
  @keyframes sbFadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes sbFadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes sbFadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes sbFadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes sbFadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
.transitionless-state {
  transition: none!important;
  * {
    transition: none!important;
  }
}
