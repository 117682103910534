﻿.listing {
    display: block;
    list-style: none;
}

    .listing__item {
        @extend .clearfix;
        display: block;
        padding: $line-height-computed*0.5 $grid-gutter-width*0.5;
        border-bottom: 1px solid $gray-lighter;

        &:last-child {
            border-bottom: 0;
        }
        small { display:block; }
    }


.listing--inline {
    display: inline-block;
    list-style: none;


    .listing__item {
        @extend .clearfix;
        display: inline-block;
        padding: 0 $grid-gutter-width*0.25 0 0;
        margin: 0 $grid-gutter-width*0.25 0 0;
        border-bottom: 0;
        border-right: 1px solid $gray-lighter;

        &:last-child {
            border-right: 0;
        }
    }
}


.listing--striped {
    .listing__item {
        &:nth-child(odd) {
            background-color: $table-bg-accent;
        }
    }
}


.listing--hover {
    .listing__item {
        transition: background-color 0.25s ease;
        cursor: pointer;
        
        &:hover { 
            background-color: $gray-lighter;
        }
    }
}

.listing--counters {
    counter-reset: listing-counter;
    .listing__item {
        counter-increment: listing-counter;
        &:before {
            content: counter( listing-counter )".";
            display: inline-block;
            margin-right: 5px;
            font-weight: 600;
        }
    }
}


.listing--bordered {
    border: 1px solid $gray-lighter;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.listing--condensed {
    .listing__item {
        padding: $line-height-computed*0.25 $grid-gutter-width*0.5;
    }
}

.cursor-pointer{
    cursor: pointer;
}

.bulleted-list {
    list-style: disc !important;
    padding-left: 1em !important;
}