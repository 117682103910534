﻿.news-article {
    position: relative;
    margin: 0;
}

    .news-article__actions {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        opacity: 1;
    }

        .news-article__action {
            display: block;
            margin: 0 0 $line-height-computed*0.25;
        }

    .news-article__image {
        max-width: 50%;
        margin: 0 0 $line-height-computed;
    }

    .news-article__header {
        margin: 0 0 $line-height-computed;
    }

    .news-article__headline {
        margin: 0 0 $line-height-computed;
    }

    .news-article__body {

    }

.js-article {
    ul {
        padding-left: 2em;
        list-style: disc;
    }

    ol {
        padding-left: 2em;
        list-style: decimal;
    }

    a {
        color: $brand-primary;
        text-decoration: underline;
    }
}

@media (max-width: $screen-sm) {
    .news-article__image {
        max-width: 100%;
    }
}