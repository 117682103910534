// Main colours

$brand-primary: var(--brand-primary);
$brand-secondary: var(--brand-secondary);
$brand-family: #0762F2;
$brand-success: #1ECD6E;
$brand-info: #4B8FFA;
$brand-warning: #F59D00;
$brand-danger: #fc5255;

$faria-black: #293041;
$faria-off-black: #45546c;
$faria-gray-2: #5a6a8f;
$gray-base: darken($faria-black, 20%);
$gray-010: lighten($gray-base, 1%);
$gray-020: lighten($gray-base, 2%);
$gray-135: lighten($gray-base, 13.5%); // #222
$gray-230: lighten($gray-base, 23%);
$gray-265: lighten($gray-base, 26.5%); // #444
$gray-600: lighten($gray-base, 60%); // #999
$gray-790: lighten($gray-base, 79%); // #ccc
$gray-800: lighten($gray-base, 79%);
$gray-935: lighten($gray-base, 93.5%); // #eee
$gray-980: lighten($gray-base, 98%);
$gray-990: lighten($gray-base, 99%);

// Night mode

$night-base: #202940;
$night-010: lighten($night-base, 1%);
$night-020: lighten($night-base, 2%);
$night-080: lighten($night-base, 8%);
$night-135: lighten($night-base, 13.5%); // #222
$night-240: lighten($night-base, 24%);
$night-265: lighten($night-base, 26.5%);
$night-400: lighten($night-base, 40%);
$night-600: lighten($night-base, 60%); // #999
$night-790: lighten($night-base, 79%); // #ccc
$night-810: lighten($night-base, 81%); // #ccc
$night-935: lighten($night-base, 93.5%); // #eee
$night-980: lighten($night-base, 98%);
$night-990: lighten($night-base, 99%);

:root {
    --brand-primary: #745fa1;
    --brand-secondary: #33C1D0;
    --faria-black: #{$faria-black};
    --gray-010: #{$gray-010};
    --gray-020: #{$gray-020};
    --gray-135: #{$gray-135};
    --gray-265: #{$gray-265};
    --gray-600: #{$gray-600};
    --gray-790: #{$gray-790};
    --gray-800: #{$gray-800};
    --gray-935: #{$gray-935};
    --gray-980: #{$gray-980};
    --gray-990: #{$gray-990};
    --night-base: #{$night-base};
    --night-010: #{$night-010};
    --night-020: #{$night-020};
    --night-080: #{$night-080};
    --night-135: #{$night-135};
    --night-240: #{$night-240};
    --night-265: #{$night-265};
    --night-400: #{$night-400};
    --night-600: #{$night-600};
    --night-790: #{$night-790};
    --night-810: #{$night-810};
    --night-935: #{$night-935};
    --night-980: #{$night-980};
    --night-990: #{$night-990};
    --translucent: rgba(255, 255, 255, 0.75);
    --gray-gradient: linear-gradient(to bottom,#859bbb,#5b6c86);
    --gray-translucent: rgba(0, 0, 0, 0.5);
    --table-bg-accent: #FBFCFF;
    --solid-bg: var(--brand-primary);

    // Default theme fallback
    --white: #FFFFFF;
    --gray-base: #000000;
    --gray-darker: var(--gray-135);
    --gray-dark: var(--gray-265);
    --gray-dark2: var(--gray-230);
    --gray: var(--gray-600);
    --gray-light: var(--gray-790);
    --gray-light2: var(--gray-800);
    --gray-lighter: var(--gray-935);
    --off-white: var(--gray-980);
    --alost-white: var(--gray-990);
    --header-bg: var(--white);
    --solid-bg: var(--brand-primary);
    --diary-picker-bg: var(--brand-primary);
    // -webkit-text-fill-color: var(--faria-black);
}

$white: var(--white);
$gray-base: var(--gray-base);
$gray-darker: var(--gray-darker);
$gray-dark: var(--gray-dark);
$gray: var(--gray);
$gray-light: var(--gray-light);
$gray-lighter: var(--gray-lighter);
$translucent: var(--translucent);
$gray-gradient: var(--gray-gradient);
$off-white: var(--off-white);
$almost-white: var(--almost-white);
$gray-translucent: var(--gray-translucent);
$text-color: var(--text-color);

// Other colours

$brand-gold: #FFD700;
$light-blue: #4B8FFA;
$border-color: #D9E7F7;
$background-light: $off-white;

// Grays

$gray-base: var(--gray-base);
$gray-darker: var(--gray-darker); // #222
$gray-dark: var(--gray-dark); // #444
$gray-dark2: var(--gray-dark2);
$gray: var(--gray); // #999
$gray-light: var(--gray-light); // #ccc
$gray-light2: var(--gray-light2);
$gray-lighter: var(--gray-lighter); // #eee
$translucent: rgba(255, 255, 255, 0.75);
$gray-gradient: linear-gradient(to bottom,#859bbb,#5b6c86);
$off-white: var(--off-white);
$almost-white: var(--almost-white);
$gray-translucent: rgba(0, 0, 0, 0.5);
$white: var(--white);

$gray-gradient: linear-gradient(to bottom,#859bbb,#5b6c86);

// Body defaults

//** Background color for `<body>`.
$body-bg: #f9f9f9;
//** Global text color on `<body>`.
$text-color: $gray-darker;

//** Global textual link color.
$link-color: $brand-primary;
//** Link hover color set via `darken()` function.
$link-hover-color: $link-color;
//** Link hover decoration.
$link-hover-decoration: underline;