﻿.sb-checkbox {
    display: inline-block;
}

.planner-checkbox {
    position: relative;
    height: 1.45em;
    width: 1.45em;
    visibility: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

   &::before {
        visibility: visible;
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        transition: background-color 0.25s ease;
        border: 1px solid $gray-light;
        border-radius: 3px;
        cursor: pointer;
        transition: all 0.25s ease;
        background-color: $white;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        // background-color: $gray-light;
    }

    &:hover::before {
        border-color: $gray;
    }

    &::after {
        content: "";
        height: 1.15em;
        width: 1.15em;
        border-radius: 3px;
        z-index: 1;
        visibility: visible;
    }

    &.disabled::before {
        background-color: $gray-light;
        cursor: not-allowed;
    }

    &.disabled::after {
        cursor: not-allowed;
    }
}

.planner-checkbox--lg {
    height: 30px;
    width: 30px;

   &::after {
        height: 24px;
        width: 24px;
    }
}

.planner-checkbox--success {
   &::after {
        background-color: $brand-success;
    }
}

.planner-checkbox--warning {
   &::after {
        background-color: $brand-warning;
    }
}

.planner-checkbox--info {
    &::after {
         background-color: $brand-info;
     }
 }

.planner-checkbox--danger {
   &::after {
        background-color: $brand-danger;
    }
}

.planner-checkbox--primary {
    &::after {
         background-color: $brand-primary;
     }
 }

 .planner-checkbox--secondary {
    &::after {
         background-color: $brand-secondary;
     }
 }

 .planner-checkbox--success, .planner-checkbox--warning, .planner-checkbox--info, .planner-checkbox--danger, .planner-checkbox--primary, .planner-checkbox--secondary {
    &.disabled:after {
        background-color: $gray;
    }
 }

label {
    .planner-checkbox {
        margin-left: 1em;
        margin-top: 0;
        margin-right: 0.25em;
        vertical-align: top;
    }
   &:first-of-type{
        .planner-checkbox {
            margin-left: 0;
        }
    }
}
