﻿.sticky {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  z-index:100;
}

.sticky-top {
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0;
}

.sticky-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
}

