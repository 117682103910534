
// Right-to-left locale support.
//
// Style for right-to-left displayed locales.

@use "sass:math";

.fal,
.far,
.fa {
    &.show-rtl {
        display: none;
    }
}

html[dir="rtl"] {

    @include make-rtl-grid(xs);
    // Small grid
    //
    // Columns, offsets, pushes, and pulls for the small device range, from phones
    // to tablets.
    @media (min-width: $screen-sm-min) {
        @include make-rtl-grid(sm);
    }
    // Medium grid
    //
    // Columns, offsets, pushes, and pulls for the desktop device range.
    @media (min-width: $screen-md-min) {
        @include make-rtl-grid(md);
    }
    // Large grid
    //
    // Columns, offsets, pushes, and pulls for the large desktop device range.
    @media (min-width: $screen-lg-min) {
        @include make-rtl-grid(lg);
    }

    .title-bar__utility {
        left: 0px;
        right: auto;
    }

    .pull-right {
        float: left !important;
    }

    .pull-left {
        float: right !important;
    }

    .pull-right > .dropdown-menu {
        left: 0;
        right: auto;
    }

    .btn-social {
        &.btn {
            text-align: right;
            padding: 2px 44px 2px 0;
        }

        & > :first-child {
            right: 0px;
            left: auto;
        }
    }

    .text-success,
    .text-warning,
    .text-danger {
        &.glyphicon {
            padding-left: 0.5rem;
            padding-right: 0px;
        }
    }

    .dashboard-widgets {
        .col-md-6 {
            &:nth-child(odd) {
                .widget {
                    margin-right: 0px;
                    margin-left: floor(math.div($grid-gutter-width, 3));
                }
            }

            &:nth-child(even) {
                .widget {
                    margin-right: floor(math.div($grid-gutter-width, 3));
                    margin-left: 0px;
                }
            }
        }
    }

    .navbar-nav {
        float: right;

        > li {
            float: right;

            @media (max-width: $screen-md) {
                float: none;
            }
        }


        &.navbar-top {

            @media (min-width: $screen-md-min) {
                float: left;

                > li {
                    float: right;
                }
            }
        }
    }

    .navbar-toggle {
        margin-left: floor(($grid-gutter-width * 0.5));
        float: left;
    }

    .modal-footer {
        text-align: left;
    }

    .modal-dialog {
        .title-bar {
            .title-bar__title {
                padding-right: 0px;
            }
        }
    }

    .dropdown-menu {
        > li {
            > a {
                text-align: right;
            }
        }
    }

    .dropdown-menu-right {
        left: 0;
        right: auto;
    }

    .dropdown-submenu { 
        a {
            text-align: right;
            padding-right: 20px;
        }
        ul {
        left: auto;
        right: calc(-100% - 2px);
        }
    }

    .language-icon-background {
        background-position: right center;
        padding: 0px 25px 0px 0px !important;
    }


    .message-center {

        @media (min-width: 767px) {
            .dynamic-height {
                &.hidden-scroll {
                    &:hover {
                        padding-right: floor(($grid-gutter-width * 0.5));
                    }
                }
            }

            ul {
                margin-right: 1em;
            }
        }

        button {
            text-align: right;
        }
    }

    .fal,
    .far,
    .fa {
        &.hide-rtl {
            display: none;
        }

        &.show-rtl {
            display: initial;
        }
    }
}
