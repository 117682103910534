﻿.animation {
    animation: none 1s infinite linear;
    animation-fill-mode: both;
}

.animation--spinning {
    animation-name: spin;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}