.downloader {
    background: $white;
    display: inline-flex;
    align-items: center;
    position: fixed;
    bottom: 1.25rem;
    left: 1.25rem;
    z-index: 5000;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    div {
        margin-right: 2rem;
        &:last-child {
            margin-right: 0;
        }
    }
}