﻿
$btn-secondary-gray: #6c757d;

.btn {
    transition: background-color 0.25s ease, border-color 0.25s ease;
    min-width: 80px;

    &:focus {
        outline: none;
    }
}

.btn-primary, .btn-primary:visited, .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background-color: $brand-primary;
    color: #fff;
    &:hover {
        background-image: $background-darken;
    }
}

.btn-auto {
    min-width: 0;
}

@media (max-width: $screen-xs-max) {
    .btn-xs-auto {
        min-width: 0;
    }
}

.btn-secondary {
    color: #fff;
    background-color: $btn-secondary-gray;
    border-color: $btn-secondary-gray;
    &:hover, &:focus, &:active {
        color: $gray-lighter;
    }
}

.btn-xs-icon {
    i {
        margin-right: 0.25rem;
    }
}

@media (max-width: $screen-xs-max) {
    .btn-xs-icon {
        i {
            font-size: 2rem;
            margin-right: 0;
        }
        &[disabled] {
            opacity: $hover-opacity;
        }
    }

    .btn-xs-icon, .btn-xs-icon:visited, .btn-xs-icon:hover, .btn-xs-icon:active {
        &, &.btn-primary, &.btn-warning, &.btn-danger, &.btn-info, &.btn-secondary {
            min-width: auto;
            border-color: transparent;
            background-color: transparent;
            box-shadow: none;
            color: $brand-primary;
        }

        &.btn-success {
            color: $brand-success;
        }

        &.btn-warning {
            color: $brand-warning;
        }

        &.btn-danger {
            color: $brand-danger;
        }

        &.btn-info {
            color: $brand-info;
        }

        &.btn-secondary {
            color: $btn-secondary-gray;
        }
    }
}

.btn-register {
    margin-left: 5px;
}

.btn-link:hover, .btn-link:focus, .btn-link:active {
    text-decoration: none;
    opacity: $hover-opacity;
}

.btn-loading {
    .glyphicon {
        display: none;
    }

    .btn-loading__icon {
        display: inline-block;
    }
}

.btn-loading__icon {
    display: none;
}

.btn-sb {
    //@include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}

.btn-recipient {
    padding: 2px;
    margin: 0 4px 4px 0;
}

.time-boxes {
    display: block;


    button {
        border-radius: 3px;
        margin: 3px;
        min-width: auto;
        font-size: 1.35rem;
    }

    button.disabled {
        color: #cccccc;
    }
}

.btn-responsive-toolbar .btn {
    margin-bottom: 4px;
}

.btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 2px;
}

.btn-xs {
    padding: 2px 5px;
    border-radius: 2px;
}

.btn-bottom {
    vertical-align: bottom;
}

.dropdown-toggle {
    display: block;
    padding: 5px 0px;
    line-height: 20px;
    cursor: pointer;
}

.dropdown-menu {
    a {
        cursor: pointer;
    }
}

.min-width-auto {
    min-width:initial;
}

.btn-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
        &:first-of-type {
            flex-grow: 1;
        }
        &:last-of-type {
            padding-left: 5px;
        }
    }
    &.btn-inactive > div:last-of-type{
        visibility: hidden;
    }
}

.btn-inactive {
    opacity: 0.5;
    filter: grayscale(80%);
}

.btn i {
    margin-right: 3px;
}

.btn-i i {
    margin-right: 0;
}

.btn-light {
    background: $gray-lighter;
}

.cursor-default {
    cursor: default;
/*Ripple effect*/
}

.ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }

.ripple:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $gray-lighter 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
}

.ripple:active:after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
}

.cursor-default {
    cursor: default!important;
}

sb-export-button {
    .btn {
        font-size: 1.5rem;
    }
}

.btn-group-vertical > :only-child {
    margin-top: -5px;
}

.no-hover:hover {
    opacity: 1!important;
}

// Hover for detail buttons

.hover-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .hover-detail__list {
        display: flex;
        align-items: center;
        visibility: hidden;
        pointer-events: none;
        > * {
            padding: 0.5rem;
        }
    }
    &:hover {
        .hover-detail__list {
            visibility: visible;
            pointer-events: all;
        }
    }
}

.logged-out .locale-picker .dropdown-toggle {
    color: $gray-darker;
    background: $white;
    &:hover, &:active {
        color: $gray-darker;
        background: $white;
    }
}
