﻿.wysiwyg {
    ul {
        list-style: square outside;
        padding-left: 18px;
        margin-bottom: $line-height-computed;
    }

    ol {
        list-style: decimal outside;
        padding-left: 23px;
        margin-bottom: $line-height-computed;
    }

    p {
        margin-bottom: $line-height-computed;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    table {
        border-collapse: inherit;
        border-spacing: 2px;
    }

    td, th {
        margin:auto;
        padding: initial;
    }
}

// styles specific to the angular markdown editor
.md-editor {
    .btn-sm, .btn-group-sm > .btn,
    .btn {
        min-width: inherit;
    }
}

// textAngular html editor
.ta-root {

    .btn {
        min-width: auto;
    }

    .ta-toolbar {
        background-color: #F0F0F0;
        padding: 10px 10px 5px;
        margin-left: 0px; /* Override bootstrap */
        border: 1px solid #EEE;
    }

    .ta-toolbar .btn-group {
        margin-bottom: 5px;
    }

    .ta-editor, .white-box {
        background-color: $white;
        border: 1px solid #EEE;
    }

    .ta-editor {

        ul, ol, dl {
            display: block;
            margin-top: 1em;
            margin-bottom: 1em;
            margin-left: 0;
            margin-right: 0;
            padding-left: 40px;
        }

        ul {
            list-style-type: disc;
        }

        ol {
            list-style: decimal;
        }
    }
}

.formattedHtml,
.welcomeMessageView {

    p {
        margin-bottom: 1em;
    }

    a {
        color: $link-color;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    ul, ol, dl {
        display: block;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style: decimal;
    }
}