sb-action-menu {
    $border-style: solid 1px $table-border-color;
    .action-menu {
        @extend .bg-info-lighter;
        width: 100%;
        padding: 1rem;
        > * {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            border: $border-style;
            @extend .panel, .pointer;
            &:hover {
                opacity: $hover-opacity;
            }
            > * {
                padding: 0.75rem;
                &:first-child {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    > * {
                        &:first-child {
                            position: relative;
                            margin-right: 2rem;
                            > * {
                                &:first-child {
                                    > * {
                                        width: 40px;
                                        height: 40px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border: $border-style;
                                        border-radius: $border-radius-small;
                                        padding: 0.33rem;
                                        font-size: 2.6rem;
                                    }
                                }
                                &:nth-child(2) {
                                    color: $brand-primary;
                                    opacity: 0.8;
                                    position: absolute;
                                    right: 0;
                                    bottom: 0;
                                    text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
                                }
                            }
                        }
                        &:last-child {
                            > * {
                                &:first-child {
                                    font-weight: $semi-bold;
                                    margin-bottom: 0.5rem;
                                }
                            }
                        }
                    }
                }
                &:last-child {
                    padding: 1.5rem;
                    font-size: 1.5rem;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        &.action-menu-featured {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-evenly;
            align-items: flex-start;
            background: transparent;
            padding: 0;
            > * {
                background: transparent;
                box-shadow: none;
                margin-bottom: 0;
                border: 0;
                flex-basis: 100px;
                flex-grow: 1;
                justify-content: center;
                text-align: center;
                > * {
                    display: flex;
                    flex-direction: column;
                    > * {
                        &:first-child {
                            margin-right: 0;
                            > * {
                                &:first-child {
                                    > * {
                                        width: 50px;
                                        height: 50px;
                                        padding: 0.33rem;
                                        font-size: 3rem;
                                        margin: 0rem 0rem 1.5rem 0rem;
                                        border-radius: 50%;
                                        background: $white;
                                        box-shadow: $box-shadow-round;

                                    }
                                }
                                &:nth-child(2) {
                                    bottom: 0.85rem;
                                }
                            }
                        }
                        &:last-child {
                            > * {
                                &:first-child {
                                    font-size: 1.5rem;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}