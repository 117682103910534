sb-side-menu-nav-pill {
        .nav-pills {
            margin-top: 10px;
        > li a {
            border-radius: 0;
        }
        > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
            @extend .sb-bg-light;
            color: var(--brand-primary)!important;
            font-weight: bold;
            border-radius: 0;
            @media (min-width: $screen-md-min) {     
                border-right: solid;
            }
        }

    }
}