$timeline-item-width: 50px;
$timeline-bar-width: 4px;
.timeline {
    position: relative;
    > div {
        position: relative;
        &:before {
            content: ' ';
            height: 100%;
            border-left: solid $timeline-bar-width $brand-primary;
            position: absolute;
            left: $timeline-item-width * 1.5;
            top: 0;
            bottom: 0;
            z-index: 0;
        }
        > div {
            display: grid;
            grid-template-columns: $timeline-item-width $timeline-item-width auto;
            margin:1rem 0;
            min-height: $timeline-item-width;
            > div {
                display: flex;
                align-items: center;
                &:nth-child(2) {
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: $timeline-bar-width;
                    }
                &:last-child {
                    padding-left: $timeline-item-width * 0.5;
                    }
                }
                .timeline-bullet {
                    background-color: $white;
                    border: solid 2px $gray-darker;
                    border-radius: 50%;;
                    width: 26px;
                    height: 26px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    > * {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        border: solid 1px #ffffff;
                        }
                        &.timeline-bullet-moving {
                            position: absolute;
                            margin-top: 13rem;
                            border: none;
                            color: $gray-darker;
                            box-shadow: $box-shadow-round;
                            font-size: 1.75rem;
                            width: 3.2rem;
                            height: 3.2rem;
                        }
                    }
                &:first-child, &:last-child {
                    h3 {
                        color: $brand-primary;
                    }
                }
                &:first-child {
                    > div:nth-child(2) {
                        border-top: solid $timeline-bar-width $brand-primary;
                    }
                }
                &:last-child {
                    > div:nth-child(2) {
                        border-bottom: solid $timeline-bar-width $brand-primary;
                    }
                }
                &.selected-stop {
                    font-weight: $semi-bold;
                }
                &.current-stop {
                    margin-bottom: 7rem;
                }
            }
        }
        &.timeline-has-selected-stop {
            > div > div {
                &:first-child, &:last-child {
                    h3 {
                        color: $gray-base;
                    }
                }
                &.selected-stop {
                    h3 {
                        color: $brand-primary;
                    }
                }
            }

        }
        &.timeline-register > div {
            > div {
                min-height: $timeline-item-width * 0.75;
                &:first-child, &:last-child {
                    h3 {
                        color: inherit;
                        font-size: 1.3rem;
                    }
                }
                &:first-child > div:nth-child(2) {
                    border-top :0;
                }
                &:last-child > div:nth-child(2) {
                    border-bottom :0;
                }
                > div:last-child {
                    padding-left: $timeline-item-width * 0.25;
                }
            }
        }
        &.timeline-live {
            > div {
                &:before {
                    left: $timeline-item-width * 2;
                }
                > div {
                    min-height: $timeline-item-width * 1.25;
                    grid-template-columns: ($timeline-item-width * 1.5) $timeline-item-width auto;
                    > div {
                        &:first-child {
                            font-weight: $semi-bold;
                            font-size: 1.75rem;
                            flex-direction: column;
                            align-items: flex-end;
                            justify-content: center;
                            padding-right: 1rem;
                            > .timeline-warning {
                                position: absolute;
                                margin-top: 4.5rem;
                                font-weight: normal;
                                font-size: 1.25rem;
                            }
                        }
                    }
                }
            }
        }
    }