.colour-test {
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 1rem;
    > * {
        padding: 1rem;
        border-radius: $border-radius-large;
        text-align: center;
        i {
            font-size: 3rem;
            margin-bottom: 1rem;
        }
    }
    &.colour-test-main {
        > * {
            &:first-child {
                @extend .text-light;
                background-color: $brand-primary;
            }
            &:nth-child(2) {
                color: $brand-secondary;
                background-color: $brand-primary;
            }
            &:nth-child(3) {
                color: $gray-dark;
                background-color: $brand-primary;
            }
            &:nth-child(4) {
                @extend .text-dark;
                background-color: $brand-secondary;
            }
            div {
                &:last-child {
                    padding-top: 0.5rem;
                    margin-top: 0.5rem;
                    border-top: solid 1px;
                }
            }
        }
    }
    &.colour-test-all {
        color: #ffffff;
    }
}

.modal {
    colour-picker {
        > * {
            &:first-child {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                > * {
                    margin:1.5rem 0rem!important;
                }
            }
            &.colour-test {
                grid-template-columns: repeat(auto-fill, 120px);
            }
        }
    }
}

// Onboarding checklist

.onboarding-checklist {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;
    border-left: $border-info;
    > * {
        width: 100%;
    }
    .onboarding-checklist-header {
        > * {
            width: 100%;
            &:first-child {
                height: 180px;
                background-image: url(/assets/img/backgrounds/checklist.png);
                background-size: cover;
                background-position: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                > img {
                    height: 200px;
                    width: 200px;
                    border-radius: $btn-border-radius-large;
                    @extend .bg-info-lighter;
                    object-fit: contain;
                    position: absolute;
                    top: 25px;
                    box-shadow: $box-shadow;
                }
            }
            &:nth-child(2) {
                padding: 3rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 80px;
                > h2, h3 {
                    font-weight: $semi-bold;
                    margin-bottom: 1rem;
                }
            }
            &:last-child {
                padding: 1.5rem;
            }
            border-bottom: $border-info;
        }
    }
    .action-menu {
        padding-top: 2rem;
        padding-bottom: 3rem;
    }
}
