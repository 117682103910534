@import '../thirdparty/bootstrap-4.5.3-custom.scss';

// Modals

.modal.show {
  opacity: 1;
}

.modal-content {
  border: 0;
}

.modal-header {
  align-items: center;
  padding: 12.5px 15px;
  margin: 0;
  border: 0;
  h3 {
      font-size: 2rem;
  }
}

// Tooltips

.tooltip {
  font-size: 1.3rem;
}

// Timepicker

.ngb-tp-input-container {
  width: 5.5rem!important;
}

// Responsive

.visible-xl-min {
  display: none!important;
  @media(min-width: $screen-xl-min) {
    display: flex!important;
  }
}

// Dropdown
// Changed from xs to sm boundary to get around behaviour on dropdown where it shifts to bottom-left placement below 768px
// causing it to be hidden off to the right of screen.
@media(max-width: $screen-sm) {
  .dropdown-sm-fullscreen {
      position: fixed!important;
      top: 0!important;
      left: 0!important;
      right: 0!important;
      bottom: 0!important;
      transform: none!important;
      pointer-events: all!important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: $gray-translucent;
      padding: 10vw;
      pointer-events: none;
      margin: 0;
      border-radius: 0;
      -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 0.5s; /* Firefox < 16 */
      -ms-animation: fadein 0.5s; /* Internet Explorer */
      -o-animation: fadein 0.5s; /* Opera < 12.1 */
          animation: fadein 0.5s;
      > div {
          width: 100%;
          background-color: $white;
          pointer-events: all;
          padding: 0.25rem 0;
          &:first-child {
              border-top-left-radius: $border-radius-large;
              border-top-right-radius: $border-radius-large;
              padding-top: 0.5rem;
          }
          &:last-child {
              border-bottom-left-radius: $border-radius-large;
              border-bottom-right-radius: $border-radius-large;
              padding-bottom: 0.5rem;
          }
      }
  }
  .open > .dropdown-menu-lg {
      display: flex;
  }
}
