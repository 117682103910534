﻿.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-sm-50 {
    @media (min-width: $screen-sm) {
        width: 50% !important;
    }
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
    max-width: 100% !important;
}

.w-xs-100 {
    @media (max-width: $screen-xs-max) {
        width: 100% !important;
        max-width: 100% !important;
    }
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}


.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important
}

.max-vw-5 {
    max-width: 5vw !important
}
.w-8-rem {
    width: 8rem
}



.min-vh-100 {
    min-height: 100vh !important
}

.vw-100 {
    width: 100vw !important
}

.text-1-rem {
    font-size: 1rem;
}
