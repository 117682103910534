﻿* {
    box-sizing: border-box;

    &:before,
    &:after {
        box-sizing: border-box;
    }
}

body {
    -webkit-backface-visibility: hidden;
}

a {
    color: $text-color;
    text-decoration: none;
    transition: color 0.25s ease;

    &:focus,
    &:hover {
        color: $gray-darker;
        text-decoration: none;
    }
}

ul, ol, dl {
    margin: 0;
    padding: 0;
    list-style: none;
}

h1, h2, h3, h4, h5, h6, p, li {
    margin: 0;
    padding: 0;

    &.reset {
        margin-top: $line-height-computed;
        margin-bottom: ($line-height-computed * 0.5);

        & + hr {
            margin-top: ($line-height-computed * 0.5);
        }
    }
}


strong {
    font-weight: 600;
}

img {
    max-width: 100%;
}

legend {
    font-size: $font-size-large;
    margin: 0 auto $line-height-computed;
    padding: $line-height-computed 0 $line-height-computed*0.5 0;
}

label {
    margin: 0;
    font-weight: 600;
}

pre {
    border: 0;
    padding: 0;
    margin: 0;
    background: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
