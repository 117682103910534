﻿.chat-message {
    position: relative;
    display: block;
    margin: 0 25% $line-height-computed*0.5 $grid-gutter-width*0.5;
    padding: $line-height-computed*0.5 $grid-gutter-width*0.5;
    border: 1px solid $gray-light;
    background: $white;

    &:before,
    &:after {
        content: "";
        position: absolute;
        display: block;
        bottom: -1px;
        right: 100%;
        margin: 0;
        height: 0;
        width: 0;
        border-bottom: $grid-gutter-width*0.5 solid $gray-light;
        border-left: $grid-gutter-width*0.5 solid transparent;
    }

    &:after {
        border-bottom-color: $white;
        margin: 0 -3px 1px 0;
    }
}


.chat-message--rev {

    margin: 0 $grid-gutter-width*0.5 $line-height-computed*0.5 25%;

    &:before,
    &:after {
        left: 100%;
        border-left: 0;
        border-right: $grid-gutter-width*0.5 solid transparent;
    }

    &:after {
        margin: 0 0 1px -3px;
    }
}


.chat-message--shaded {
    background: #f9f9f9;  // same as booststrap, but they don't use a variable
    
    &:after {
        border-bottom-color: #f9f9f9; 
    }
}

