﻿.cca-list {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    .card {
        min-height: 220px;
        justify-content: space-between;
        max-width: 100%;
        margin: 0;
        > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            &:first-child {
                align-items: flex-start;
            }
            &:last-child {
                align-items: flex-end;
                > div {
                    &:first-child {
                        > div {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 0.5rem;
                            > div {
                                display: flex;
                                align-items: center;
                                padding-right: 2rem;
                            }
                        }
                    }
                }
                flex-direction: column;
                align-items: flex-start;
                > div {
                    &:last-child {
                        display: flex;
                        justify-content: flex-end;
                        flex-wrap: wrap;
                        width: 100%;
                        button {  
                            margin-top: 15px;
                        }
                    }
                }
            }
            > div {
                margin-bottom: 0.25rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .avatar-initials, .avatar-image {
            height: 30px;
            width: 30px;
        }
    }
}

.book-icon {
    font-size: 3rem;
    padding-right: 1rem;
}

.info-area {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: solid 1px $gray-lighter;
    .info-area__close {
        position: absolute;
        top: 0rem;
        right: 1rem;
        font-size: 2rem;
        line-height: 0;
        padding-top: 0;
    }
}

.table > tbody > tr.tr-preference-details > td {
    border-top: 0;
}

.preference-person {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    padding: 0.5rem 0;
}

.card.preference-card {
    width: 100%;
    align-items: stretch;
    h2 {
        font-weight: 600;
        font-size: 2rem;
    }
    > div {
        &:first-child {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
    .preference-card__info {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row-reverse;
        .preference-card__key-info {
            border: solid 1px $gray-light;
            border-radius: $border-radius-large;
            padding: 1.5rem;
            display: flex;
            flex-wrap: wrap;
            font-size: 1.6rem;
            align-self: flex-start;
            > div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                > div {
                    padding: 2rem;
                }
            }
            .badge {
                font-size: 1.4rem;
            }
        }
        .preference-card__other-info {
            padding-right: 3rem;
            max-width: 700px;
        }
    }
    .preference-card__options {       
        .table, table.table-mobile {
            tbody {
                tr {
                    td {
                        text-align: left;
                        label {
                            @extend .align-center;
                            input[type="checkbox"] {
                                margin-right: 1rem;
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: $screen-xs-max) {
    .card.preference-card {
        font-size: 1.5rem;
        > div {
            &:first-child {
                flex-direction: column;
            }
        }
        .preference-card__info {
            flex-direction: column;
            .preference-card__key-info {
                flex-direction: column;
                width: 100%;
                > div {
                    &:first-child {
                        margin-bottom: 2rem;
                    }
                    > div {
                        padding: 0;
                    }
                }
            }
            .preference-card__other-info {
                padding-right: 0;
            }
        }
    }
}

.preference-checkbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    > span {
        font-size: 1.5rem;
        margin-top: 0.5rem;
    }
}

// Instant booking table

table.table-bookable {
    .table-bookable__tr-date {
        background: transparent;
        > td {
            border: 0;
            padding-top: 2.5rem;
        }
    }
    .table-bookable__td-pupils {
        border-top: 0;
        text-align: right;
        border-bottom: 1px solid $table-border-color;
        padding-top: 5px;
        > div {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            flex-wrap: wrap;
            > * {
                flex-direction: column;
                align-items: flex-end;
                padding: 20px 5px 5px 15px;
                button {
                    margin-right: -1rem;
                    i {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    table.table-bookable {
        display: flex;
        tr { 
            display: flex;
            flex-wrap: wrap;
            td {
                flex-basis: 100%;
                &.table-bookable__td-time, &.table-bookable__td-price {
                    flex-basis: 35%;
                }
                &.table-bookable__td-title, &.table-bookable__td-places {
                    flex-basis: 65%;
                }
                &.table-bookable__td-price {
                    text-align: right;
                }
            }
        }
    }
}

.timeslot-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
    @media(max-width: $screen-xs-max) {
        grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
        .btn {
            padding: 1rem;
        }
    }
}

// Schedule grid for booking

.schedule-grid {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1.6fr 4fr;
    > * {
        padding: 0.25rem 0.5rem;
        display: flex;
        align-items: center;
        &.schedule-grid__clash {
            position: relative;
            $border: solid 2px $brand-danger;
            color: $brand-danger;
            border-right: $border;
            padding-right: 1rem;
            font-size: 1rem;
            text-transform: uppercase;
            justify-content: flex-end;
            font-weight: $semi-bold;
        }
    }
}
.sb-grid-xl .schedule-grid {
    grid-template-columns: 1fr 7fr 8fr;;
}

// Booking/payment banner

payment-provider-buttons {
    display: block;
    @extend .banner, .sticky, .sticky-top;
}
@media (max-width: $screen-xs-max) {
    payment-provider-buttons {
        padding: 1.5rem 1.25rem;
        .btn {
            font-size: 1.5rem;
            min-width: 0;
        }
        > div > .btn:first-of-type {
            padding-left: 0.25rem;
        }
    }
}

// Cancel/Delete events confirmation modal
.border-solid-light-grey {
    border: solid 1px #c9c9c9;
}

.booking-timeout-warning{
    font-size: 1.6rem !important;
}

@media (max-width: $screen-xs-max) {
    .bookable-events-banner {
        padding: 1.5rem 1.25rem;

        .btn {
            font-size: 1.5rem;
            min-width: 0;
        }

        > div > .btn:first-of-type {
            padding-left: 0.25rem;
        }
    }
}
