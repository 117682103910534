﻿.vertical-borders-md {
    @media (min-width: $screen-md-max) {
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
    }
}
.border-right-md {
    @media (min-width: $screen-md-min) {
        border-right: 1px solid #ccc;
    }
}
.border-left-md {
    @media (min-width: $screen-md-min) {
        border-left: 1px solid #ccc;
    }
}

.vertical-borders-xs {
    @media (min-width: $screen-xs-max) {
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
    }
}

.border-thin {
    border-width: 0.15rem;
}

.border-thick {
    border-width: 0.3rem!important;
}

.border-top-light {
    border-top: solid 1px $gray-light;
}