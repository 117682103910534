@import './typography';
@import './borders';
@import './colours';
@import './grid';

//== Navbar
//
//##

// Basics of a navbar
$navbar-height: 55px;
$navbar-margin-bottom: $line-height-computed;
$navbar-border-radius: $border-radius-base;
$navbar-padding-horizontal: floor(($grid-gutter-width * 0.5));
$navbar-padding-vertical: (($navbar-height - $line-height-computed) * 0.5);
$navbar-collapse-max-height: 340px;

$navbar-default-color: #777;
$navbar-default-bg: #f8f8f8;
$navbar-default-border: darken($navbar-default-bg, 6.5%);

// Navbar links
$navbar-default-link-color: #777;
$navbar-default-link-hover-color: #333;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: #555;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%);
$navbar-default-link-disabled-color: #ccc;
$navbar-default-link-disabled-bg: transparent;

// Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color;
$navbar-default-brand-hover-color: darken($navbar-default-brand-color, 10%);
$navbar-default-brand-hover-bg: transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg: #eee;
$navbar-default-toggle-icon-bar-bg: #999;
$navbar-default-toggle-border-color: #ccc;


//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: $gray-light2;
$navbar-inverse-bg: #222;
$navbar-inverse-border: darken($navbar-inverse-bg, 10%);

// Inverted navbar links
$navbar-inverse-link-color: $gray-light2;
$navbar-inverse-link-hover-color: #fff;
$navbar-inverse-link-hover-bg: transparent;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg: darken($navbar-inverse-bg, 10%);
$navbar-inverse-link-disabled-color: #444;
$navbar-inverse-link-disabled-bg: transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color: $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color: #fff;
$navbar-inverse-brand-hover-bg: transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333;
$navbar-inverse-toggle-icon-bar-bg: #fff;
$navbar-inverse-toggle-border-color: #333;
