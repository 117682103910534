﻿.pagination  {
    margin: 20px 0;
}

.pagination li.summary {
    display:inline-block;
    padding: 6px 12px;
}

.pagination-sm > li > a, .pagination-sm > li > span {
    border: solid 1px $gray-light;
    margin: 0.5rem 0.25rem;
    box-shadow: $box-shadow;
}

.pagination > .active > a {
    border-radius: $border-radius-base;
}

.pagination-pagesize {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column-reverse;
    @media (min-width: $screen-sm-min) {
        flex-direction: row;
        align-items: center;
    }

    .pagesize {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.pagination-mt-minus-3 {
    margin-top: -$size-3;
}

.pagination-mb-minus-3 {
    margin-bottom: -$size-3;
}