$diary-item-left-width: 62px;
.diary {
    padding: 0px;
    > div {
        .card-diary-container {
            position: relative;
            .card-diary {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;
                z-index: 1;
                @media (min-width: $screen-sm-min) {
                    flex-direction: row;
                }
                > div {
                    display: flex;
                    flex-grow: 1;
                    &:first-child {
                        padding: 0px;
                        align-items: stretch;
                        flex-basis: 75%;
                        > div {
                            &:first-child {
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                                > div {
                                    padding: 1rem;
                                    border: solid 1px $gray-lighter;
                                    flex-basis: 50%;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    flex-basis: $diary-item-left-width;
                                    flex-grow: 1;
                                    &:first-child {
                                        border-width: 0px 1px 1px 0px;
                                        font-weight: $semi-bold;
                                        > div {
                                            &:first-child {
                                                font-size: 1.3rem;
                                            }
                                            &:nth-child(2) {
                                                font-size: 1.5rem;
                                            }
                                        }
                                    }
                                    &:last-child {
                                        font-size: 1.3rem;
                                        border-width: 0px 1px 0px 0px;
                                        > div div {
                                            color: $gray;
                                            &:first-child {
                                                color: $gray-darker;
                                                font-weight: $semi-bold;
                                            }
                                        }  
                                    }
                                }                
                            }
                        }
                    }
                    &:last-child {
                        flex-direction: column;
                        align-items: flex-end;
                        justify-content: flex-end;
                        > div {
                            &.card-diary__actions, &.card-diary__multiday {
                                @media(max-width: $screen-xs-max) {
                                    border-top: solid 1px $gray-lighter;
                                }
                                display: flex;
                            }
                            &.card-diary__actions {
                                width: 100%;
                                justify-content: flex-end;
                                padding: 0.4rem 1rem;
                                text-align: right;
                                flex-wrap: wrap;
                                .dropdown-menu {
                                    z-index: 2;
                                }
                            }
                            &.card-diary__multiday {
                                align-items: center;
                                width: 100%;
                                > * {
                                    padding: 1rem 0.5rem;
                                    &:first-child {
                                        display: flex;
                                        flex-basis: $diary-item-left-width;
                                        font-size: 1.75rem;
                                        color: $gray;
                                        justify-content: center;
                                        align-items: center;
                                    }
                                    &:last-child {
                                        display: grid;
                                        grid-gap: 0.25rem 1rem;
                                        grid-template-columns: 1fr 1fr;
                                    }
                                }
                            }
                        }
                    }
                }
                &.card-diary-dropdown-active {
                    overflow: visible;
                    z-index: 2;
                }
            }
            .card-diary-state {
                padding-left: 1rem;
                display: flex;
                align-items: flex-start;
                z-index: 1;
                > sb-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 2rem;
                    width: 2rem;
                    font-size: 2rem;
                }
            }
        }
    }
}
.dashboard-widgets .diary > div .card-diary-container .card-diary {
    flex-direction: column;
    > div:last-child > div {
        &.card-diary__actions, &.card-diary__multiday {
                border-top: solid 1px $gray-lighter;
        }
    }
}
.diary-date {
    width: 100%;
    text-align: center;
    margin: 35px 0 10px;
    border-top: solid 1px $gray-light;
    > div {
        display: inline-block;
        background: $white;
        color: $gray;
        padding: 0.1rem 0.75rem;
        font-size: 1.2rem;
        border-radius: 15px;
        border: solid 1px $gray-light;
        bottom: 10px;
        position: relative;
    }
}

.dashboard-item-content {
    > div {
        .diary-date {
            border-color: $gray-light;
        }
        &:first-child {
            .diary-date {
                margin-top: 10px;
            }
        }
    }
}

.diary-event-detail-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: 1rem;
    align-items: space-between;
    position: relative;
    width: 100%;
    h3 {
        font-weight: $semi-bold;
        line-height: 2rem;
    }
    .diary-card__user {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        flex-grow: 1;
        gap: 1.5rem;
        > div {
            &.diary-card__user__count {
                @extend .text-muted;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-left: 2rem;
                flex-grow: 1;
                @media (min-width: $screen-sm-min) {
                    flex-basis: 50%;
                    padding-left: 1rem;
                    justify-content: flex-start;
                    flex-grow: 0;
                }
                > div > div {  
                    white-space: nowrap;
                }
            }
        }
    }
    .diary-card__extra-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        div {
            &.card-diary__location {
                flex-basis: 30%;
                flex-grow: 1;
            }
        }
    }
}

.diary-time {
    @extend .row-primary, .text-muted, .semi-bold, .pl-2;
    border-width: 0.2rem; 
}

.diary-scroll-area {
    overflow-y: auto;
    overflow-x: hidden;
    .sticky-footer {
        @extend .no-gutter;
        position: relative;
        float: left;
        background: transparent;
        color: #000;
        padding-bottom: 25px;
        display: none;
    }
    &.loaded {
        .sticky-footer {
            display: block;
        }
    }
}

//Invitations

.diary-scroll-area .invitations {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1.5rem;
    margin-top: 1rem;
    > div {
        margin: 0;
    }
    .card {
        align-items: flex-end;
    }
}