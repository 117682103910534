.tiles-grid-list {
  > * {
    margin-top: 25px;
    &:first-child {
      margin-top: 0;
    }
  }
  h2 {
    padding: 0 0 1.5rem 0;
    font-weight: $semi-bold;
    font-size: 2.25rem;
    margin-bottom: 0;
    border: 0;
    display: flex;
    &::after {
      content: ' ';
      height: 1px;
      flex-grow: 1;
      border-top: solid 1px $gray-lighter;
      margin-top: 1.3rem;
      margin-left: 1.5rem;
    }
  }
}

$tile-border-color: $gray-light;

.tiles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 1rem;
    &::before {
        content: '';
        width: 0;
        padding-bottom: 100%;
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }
    > * {
        border: 1px $tile-border-color solid;
        box-shadow: $box-shadow;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.25rem;
        font-size: 1.4rem;
        cursor: pointer;
        &.active {
          cursor: default;
          &:hover{
            opacity: 1;
            border-color: $tile-border-color;
          }
        }
        &:hover{
          opacity: $hover-opacity;;
          border-color: $gray;
        }
        &:first-child {
            grid-row: 1 / 1;
            grid-column: 1 / 1;
          }
          > * {
            width: 100%;
            text-align: center;
            &:first-child {
              text-align: left;
              text-transform: uppercase;
              position: absolute;
              width: auto;
              i {
                font-size: 2rem;
              }
            }
            &:nth-child(2) {
              flex-basis: 70%;
              color: $gray-dark;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              padding: 1rem 0;
              > * {
                text-align: left;
                padding-right: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 2rem;
                font-weight: $semi-bold;
                &:last-child {
                  font-size: 4rem;
                  flex-grow: 1;
                  text-align: center;
                  padding-right: 0;
                }
                > * {
                      font-weight: $semi-bold;
                    }
              }
            }
            &:nth-child(3) {
              font-size: 1.6rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex-basis: 30%;
              > * {
                &:first-child {
                  font-weight: $semi-bold;
                }
                &:nth-child(2) {
                  font-size: 1.2rem;
              }
            }
          }
        }
    }
    &.tiles-grid-compare {
     > * {  
            &.tiles-grid__arrow {
              border: 0;
              box-shadow: none;
              justify-content: center;
              align-items: center;
              > * {
                text-align: center;
                line-height: 0;
                font-size: 4rem;
                color: $gray;
              }
            }
            > * {
              &:first-child {
                position: relative;
                flex-basis: 15%;
                i {
                  font-size: 2rem;
                  &.fa-arrow-right {
                    font-size: 3.5rem;
                  }
                }
              }
              &:nth-child(2) {
                flex-basis: 30%;
                align-items: center;
                > *:last-child {
                  font-size: 2.5rem;
                }
              }
            }
          }
        }
      }

@media (min-width: $screen-sm) {
  .tiles-grid {
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    > * > *:nth-child(2) > *:last-child {
      font-size: 5rem;
    }
    &.tiles-grid-compare {
      > * > *:nth-child(2) > *:last-child {
        font-size: 4rem;
      }
    }
  }
}

// Change grid to show inside list of requests

.change-grid {
  display: grid;
  grid-template-columns: 1fr 50px 1fr;
  grid-gap: 0.5rem;
  grid-auto-flow: dense;
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    i {
      width: 3rem;
      text-align: center;
    }
    &.change-grid__from {
      grid-column: 1;
    }
    &.change-grid__to {
      grid-column: 3;
    }
  }
  > sb-icon {
    grid-column-start: 2;
    grid-row: 1 / 4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}