@import './variables/side-menu';
@import './mixins';

// Text {

body {
    color: $text-color;
}

// Buttons

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    padding: 5px 10px;
    line-height: 1.428571429;
    border-radius: $border-radius-small;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // Removed for now - buttons too small
    // &:not(.btn-md):not(.btn-lg) {
    //     font-size: $form-font-size;
    // }
    &.btn-primary {

    }

    &.btn-default {
        -webkit-box-shadow: 0 1.5px 2.5px rgb(0 0 0 / 7%);
        box-shadow: 0 1.5px 2.5px rgb(0 0 0 / 7%);
    }
}

// Forms

input.form-control, select.form-control {
    display: block;
    width: 100%;
    height: 29px!important;
    padding: 5px 10px;
    font-size: $form-font-size;
    line-height: 1.428571429;
    color: #555;
    background-color: #fefefe;
    background-image: none;
    border: 1px solid $input-border-color;
    border-radius: $border-radius-small;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
}

.filter-area__search-box .btn {
    height: 29px;
}

// Checkbox

.checkbox-container {
    display: inline-grid!important;
    grid-template-columns: 23px auto;
    sb-check-box {
        display: flex;
        align-items: center;
    }
}

.planner-checkbox-container .planner-checkbox, .planner-checkbox {
    width: 15px;
    height: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #e1e1e1;
    border-radius: $border-radius-small;
    cursor: pointer;
    margin: 0!important;
    -webkit-transition: background-color .2s ease-in-out,border-color .2s ease-in-out;
    -o-transition: background-color .2s ease-in-out,border-color .2s ease-in-out;
    transition: background-color .2s ease-in-out,border-color .2s ease-in-out;
    &::after{
        height: 100%;
        width: 100%;
    }
    // !avoid to apply the style to the checkbox in the event register
    &:not(event-register &) {
            &.planner-checkbox--success {
                &::after {
                    position: absolute;
                    top: 0px;
                    line-height: 11px;
                    color: white!important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 9px;
                    height: 100%!important;
                    width: 100%!important;
                    background-color: $brand-primary;
                    border-color: $brand-primary;
                    content: "\f00c"!important;
                    font-family: 'Font Awesome 6 Pro';
            }
        }
    }
}

// Radio

.planner-radiobutton {
    width: 15px;
    height: 15px;
    &.planner-radiobutton--success{
        &::after {
            color: white!important;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 5px;
            background-color: $brand-primary;
            font-family: 'Font Awesome 6 Pro';
            font-weight: 600;
            content: "\f111";
            height: 100%;
            width: 100%;
            position: relative;
            top: 0;
            left: 0;
        }
    }
}

// Tabs

.mode-tabs {
    > div {
        background-color: $white;
        color: $text-color!important;
        font-size: 14px!important;
        font-weight: 400px!important;
        height: 32px;
        border: solid 1px $border-color!important;
        border-radius: $border-radius-base;
        padding: 0!important;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 10px!important;
        box-shadow: $box-shadow;

        &.active {
            border: solid 1px $brand-primary!important;
            border-bottom: auto!important;
        }

        i {
            margin-right: 0.75rem!important;
        }
    }

    &:not(.mode-tabs-xs) > div {
        min-width: 161px;
    }

    &.mode-tabs-xs > div {
        @media(min-width: $screen-xs-max) {
            min-width: 161px;
        }
    }

    &.mode-tabs-wrap {
        flex-wrap: wrap;
        row-gap: 1rem;
    }

    &.mode-tabs--top {
        padding-bottom: 0!important;
    }
}

// Search filter

.filter-area .filter-area-inner {
    .control-label {
        font-weight: 400;
        font-size: 12px;
        color: $text-color;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0;
    }
}

// Layout

.content__inner > * > * > *:first-of-type, .content__inner > * > *:first-of-type, .title-bar-top-container {
    > .title-bar {
        @include title-bar-top;

        > .title-bar__utility .btn-xs-icon {
            color: white;
        }
    }
}

// Side menu

$menu-text-color: #acf;
$menu-item-height: 37px;
.side-menu-bar ul.buddy-nav {
    box-shadow: none!important;
    background-color: $faria-black;
    width: $sidemenu-closed-width!important;
    .buddy-nav__item {
        padding: 0.4rem 0.5rem!important;
        .buddy-nav__link {
            color: $menu-text-color!important;
            border-radius: $border-radius-small;
            padding: 0.75rem 0.25rem!important;
            height: $menu-item-height!important;
            margin: 0!important;
            &:hover {
                background-color: $faria-off-black!important;
            }
            .buddy-nav__label {
                font-size: 12px!important;
                margin: 0!important;
            }
            .buddy-nav__icon {
                width: 32px!important;
                font-size: 18px!important;
                padding: 1.5rem!important;
                margin-left: 2px;
            }
        }
    }
    .buddy-nav__item--active {
        .buddy-nav__link {
            color: white!important;
            background: #45546c!important;
        }
    }
    &.buddy-nav.sub-menu-bar-inner-modules {
        .buddy-nav__item {
            margin: 0!important;
        }
    }
}

.buddy-nav__tooltip {
    background-color: $faria-off-black;
    .tooltip-inner {
        background-color: $faria-off-black;
        display: flex;
        align-items: center;
        color: $menu-text-color;
        height: $menu-item-height;
        min-width: $sidemenu-full-width;
        padding-left: 1rem;
    }
    .arrow {
        visibility: hidden;
    }
    @media(max-width: $screen-xs-max) {
        display: none!important;
    }
}

// Summary buttons

.balance-summary, .summary {
    img, .icon-img {
        margin: 1rem 2rem;
        width: 7rem;
        height: 7rem;
    }
}

// Alerts

.alert {
    border-left-width: 0.5rem;
    padding: 0.5rem;
    padding-left: 1rem;
    p::before {
        margin-right: 1rem;
        font-size: 1.5rem;
    }
}

// Search filter - for new form elements

@media(min-width: $screen-sm) {
    .filter-area .filter-area-inner .collapse-inner > div sb-search-filter-item > div:first-child {
        align-items: flex-start;
        padding-top: 8px;
    }
}
