@import 'variables/navbar';

// Cloak

body[cloak] {
  display: none;
}

// Modal

.modal-backdrop.fade.in {
  opacity: 0.5;
}

div[uib-modal-window] .modal-dialog {
  margin-top: 100px;
}

// Toast
.ng-toast__message .alert {
  width: 300px;
  position: fixed;
  bottom: 0rem;
  right: 1.5rem;
}

// Old sub-menu

sub-menu {
  display: none;
}

.main-view {
  padding: 0;
}

// Element alignment relative to sidemenu

@media (min-width: $screen-sm) and (hover: hover) {
  .content {
    .summary, .diary-picker, .submenu-page {
      margin-left: -5px;
    }
    header.summary {
      left: 0;
      position: relative;
      margin-left: 0;
    }
    sb-detail {
      .submenu-page {
        margin: 0;
      }
    }
  }
  reception-users-diary .diary-picker {
    margin-left: 0px !important;
  }
  sb-summary.sb-summary-hover > .summary {
    width: 100%;
    background-image: url(/assets/img/backgrounds/checklist.png) !important;
  }
}

// Accordion Card

ngb-accordion .card {
  width: 100%;
  > * {
    width: 100%;
  }
}

.card-detail {
  box-shadow: none;
}

// Date range picker (upgraded)

sb-date-range-picker {
  width: 100%;
  color: $text-color;
}

// Override out of date font awesome
.daterangepicker {
  background-color: $white !important;
  i.fa.fa-clock-o {
    &:before {
      content: '\f017' !important;
      font-weight: 400 !important;
      font-size: 2rem;
    }
  }
  select {
    margin: 0.25rem;
    padding: 0.2rem;
    width: 5.5rem !important;
    border-color: $gray-light;
    border-radius: 0.25rem;
  }

}

// Form validation

div[ng-messages][role="alert"], .field-validation-error {
  &.ng-inactive {
    display: none;
  }

  &.ng-active {
    display: flex;
  }

  padding-top: 0.5rem;
  color: $brand-danger;
}

@mixin invalid-field {
  color: $brand-danger;
  outline-color: $brand-danger;
  border-color: $brand-danger;
}

input,
select,
textarea {
  &.form-control,
  &.form-control-sm {
    &.ng-invalid {
      @include invalid-field;
    }
  }
}

label.ng-invalid {
  @include invalid-field;
}


// Loading

sb-loading.ng-isolate-scope {
  display: flex;
  padding: 0rem 2rem;
}

sb-export-button sb-loading {
  max-height: 2rem;
  align-items: center;
}
// Dropdowns - to get ui-bootstrap ones working with ngBootstrap

.dropdown {
  display: inline-flex;
  @media(max-width: $screen-xs-max) {
    flex-direction: column;
  }
}

// Locale picker

.locale-picker {
  width: 100%;
}

// Wizard

.modal {
  .wizard {
    margin-top: 2rem;
  }
}

// Master/detail

sb-detail .detail-placeholder {
  height: 95vh;
}

// Reports

report-embed > div {
  height: calc(100vh - 100px);
}

// Button

button {
  background-color: $gray-lighter;
}

// Diary filter

.diary-picker {
  background-color: var(--diary-picker-bg) !important;
}

// Card detail fix

.card-detail-container .card-detail {
  box-shadow: none !important;
  border: 0 !important;
}

.sticky-top {
  top: $navbar-height;
}
